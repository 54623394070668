import React, { useState } from "react";
import {
  Button,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { getCookie } from "../Utils";
import "./SetName.css";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { ExpandMore, Http, UploadFile } from "@mui/icons-material";
import { FB_IMAGE_WIX } from "../constants";
import { LoadingButton } from "@mui/lab";
import { useMediaQuery } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import { settingsReducer } from "./settingsReducer";
import "./Settings.css";

// Brand image inputs options
const FILE_UPLOAD = "FILE_UPLOAD";
const URL_UPLOAD = "URL_UPLOAD";

export const SetupWizard = () => {
  const [state, dispatch] = React.useReducer(settingsReducer, {
    loading: true,
    day_ranges: [],
  });

  const name = state.name;

  const [slug, setSlug] = React.useState("");
  const [myCalendars, setMyCalendars] = React.useState([]);

  const [brandImageSaveLoading, setBrandImageSaveLoading] =
    React.useState(false);
  const [brandImageUploadLocation, setBrandImageUploadLocation] =
    React.useState(FILE_UPLOAD);
  const [brandImage, setBrandImage] = React.useState("");
  const [newBrandImage, setNewBrandImage] = React.useState(null);
  const [brandImageSaveError, setBrandImageSaveError] = React.useState("");
  const [brandImageSaveSuccess, setBrandImageSaveSuccess] = React.useState("");
  const isMobile = useMediaQuery("(max-width:600px)");

  const [activeStep, setActiveStep] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState(false);

  const steps = [
    "General Info",
    "Set Image (Optional)",
    "Create Your First Calendar",
  ];

  const handleDescriptionClick = (event) => {
    dispatch({
      type: 'update-cal-description"',
      calDescription: event.target.value,
    });
  };

  const getMyCalendars = async () => {
    const res = await fetch("/api/calendar/list");
    const json = await res.json();
    setSlug(json.primary_slug);
    setMyCalendars(json.calendars);
  };

  const handleBrandImageUploadLocationChange = (event, newInput) => {
    if (newInput !== null) {
      setBrandImageUploadLocation(newInput);
      setNewBrandImage(null);
    }
  };

  const onFileImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setNewBrandImage(event.target.files[0]);
    }
  };

  const handleBrandImageSave = async () => {
    // Perform save logic to store brand image as file in S3 or as a URL
    setBrandImageSaveLoading(true);
    try {
      // TODO: Should we daisy chain the requests in .then()?
      if (brandImageUploadLocation === FILE_UPLOAD) {
        // Get the presigned URL from the server so that we can post the file in the S3 bucket
        const res = await fetch("api/user/branding/get-s3-post-url", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
          },
          body: JSON.stringify({
            file_name: newBrandImage.name,
            file_type: newBrandImage.type,
          }),
        });
        if (res.status >= 400) {
          throw res.statusText;
        }

        // Once we have it, pull out the fields and store it as form data
        // Then upload the file and data to the URL given
        const presignedUrlData = await res.json();
        const postData = new FormData();
        for (const key in presignedUrlData.fields) {
          postData.append(key, presignedUrlData.fields[key]);
        }
        postData.append("file", newBrandImage);

        const s3UploadRes = await fetch(presignedUrlData.url, {
          method: "POST",
          body: postData,
        });

        if (s3UploadRes.status >= 400) {
          throw s3UploadRes.statusText;
        }

        // Once we know this has succeeded, save the file name to the user
        const saveToDbRes = await fetch("api/user/branding/add-file-name", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
          },
          body: JSON.stringify({
            file_name: newBrandImage.name,
          }),
        });

        if (saveToDbRes.status >= 400) {
          throw saveToDbRes.statusText;
        } else {
          setBrandImageSaveSuccess("Your brand image has been updated.");
        }
      } else {
        // Post endpoint that just saves the image URL to the user
        const saveToDbRes = await fetch("api/user/branding/add-file-url", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
          },
          body: JSON.stringify({
            file_url: newBrandImage,
          }),
        });

        if (saveToDbRes.status >= 400) {
          throw saveToDbRes.statusText;
        } else {
          setBrandImageSaveSuccess("Your brand image has been updated.");
        }
      }
      // On success, reload the window so that if a new image has been
      // saved, it will be displayed on the other pages as well
      window.location.reload();
    } catch (err) {
      setBrandImageSaveError(err);
    } finally {
      setBrandImageSaveLoading(false);
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const setNameOnClick = async (evt) => {
    if (!firstName || !lastName) {
      return;
    }

    setLoading(true);

    const res = await fetch("api/user/set-name", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": getCookie("csrftoken"),
      },
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
      }),
    });

    await res.json();

    await getMyCalendars();
    setLoading(false);
    handleNext();

    // Update URL with parameters for step 1
    window.history.pushState(null, "", `?setimage`);
  };

  const setCalendarOnClick = () => {
    // Logic for setting the calendar

    // Update URL with parameters for step 3
    //window.history.pushState(null, "", window.location.origin); // This sets the URL to the base URL without any parameters
    //window.history.pushState(null, "", `/calendar/edit/${slug}`);
    //window.location.reload();
    window.history.pushState(null, "", "?setcalendar");
    handleNext();
  };

  const saveSettings = async () => {
    const response = await fetch(`/api/calendar/edit/${slug}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": getCookie("csrftoken"),
      },
      body: JSON.stringify({
        ...state,
        name,
      }),
    });

    // Handle the response here (e.g., check for errors or process the data)

    window.history.pushState(null, "", window.location.origin); // This sets the URL to the base URL without any parameters

    // Reload the page
    window.location.reload();
  };

  return (
    <div className="setup-body">
      <div className="stepper-body">
        <div className="stepper-header">
          <img
            className="brandImageHeader"
            src={FB_IMAGE_WIX}
            alt="freeblocks brand img"
          />
          <Typography variant="h4">
            Let&apos;s get you set up in Free Blocks.
          </Typography>
        </div>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      <div className="step-content">
        {activeStep === 0 && (
          <div>
            <Typography variant="h5">
              Enter your first and last name below.
            </Typography>
            <Box
              component="form"
              autoComplete="off"
              onSubmit={setNameOnClick}
              style={{ marginTop: "16px" }}
            >
              <TextField
                fullWidth
                label="First Name"
                variant="outlined"
                style={{ marginBottom: "8px" }}
                onChange={(event) => setFirstName(event.target.value)}
                required
              />
              <TextField
                fullWidth
                label="Last Name"
                variant="outlined"
                style={{ marginBottom: "8px" }}
                onChange={(event) => setLastName(event.target.value)}
                required
              />
              <Button
                disabled={loading}
                variant="contained"
                color="primary"
                onClick={setNameOnClick}
                type="submit"
                style={{
                  fontSize: "1rem",
                  textTransform: "none",
                  marginBottom: "16px",
                }}
              >
                Continue
              </Button>
            </Box>
          </div>
        )}
        {activeStep === 1 && (
          <div>
            <Card
              className="brandImageSection"
              variant="outlined"
              className="card"
            >
              <CardContent>
                <h2>Brand Image</h2>
                <p className="profileText centerAlign">
                  Your brand image will replace the default Free Blocks branding
                  and will be shown on all of your calendars.
                </p>
                {brandImage ? (
                  <img
                    className="brandImage centerAlign"
                    src={brandImage}
                    alt="current brand"
                  />
                ) : (
                  <>
                    <p className="profileText centerAlign">
                      A brand image has not been uploaded. We will default to
                      FreeBlocks branding.
                    </p>
                    <img
                      className="brandImage centerAlign"
                      src={FB_IMAGE_WIX}
                      alt="freeblocks brand img"
                    />
                  </>
                )}
                <br />
                <div className="container centerAlign">
                  <Accordion
                    defaultExpanded={!Boolean(brandImage)}
                    disableGutters
                    elevation={0}
                    style={{
                      maxWidth: "400px",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      sx={{
                        maxWidth: "400px",
                        padding: 0,
                        margin: 0,
                        color: "blue",
                        "& .MuiAccordionSummary-content": {
                          width: "auto",
                          flexGrow: 0,
                          justifyContent: "center",
                          margin: 0,
                        },
                      }}
                    >
                      Upload New Image
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{ maxWidth: "400px", padding: 0, margin: 0 }}
                    >
                      <p className="accordionText centerAlign">
                        Upload an image from your file system or from a URL:
                      </p>
                      <ToggleButtonGroup
                        className="centerAlign"
                        value={brandImageUploadLocation}
                        exclusive
                        onChange={handleBrandImageUploadLocationChange}
                        aria-label="brand image input"
                      >
                        <ToggleButton value={FILE_UPLOAD}>
                          <UploadFile titleAccess="Upload file from your system" />
                        </ToggleButton>
                        <ToggleButton value={URL_UPLOAD}>
                          <Http />
                        </ToggleButton>
                      </ToggleButtonGroup>
                      <br />
                      <br />
                      {brandImageUploadLocation === FILE_UPLOAD ? (
                        <TextField
                          size="medium"
                          type="file"
                          required
                          className="profileTextField"
                          inputProps={{
                            accept:
                              "image/png, image/jpeg, image/jpg, image/svg",
                          }}
                          onChange={onFileImageChange}
                        />
                      ) : (
                        <TextField
                          size="medium"
                          type="url"
                          placeholder="https://imagelocation.com"
                          required
                          className="profileTextField"
                          inputProps={{ maxLength: 300 }}
                          onChange={(e) => setNewBrandImage(e.target.value)}
                        />
                      )}
                      <br />
                      <br />
                      {newBrandImage && (
                        <img
                          className="brandImage centerAlign"
                          src={
                            brandImageUploadLocation === FILE_UPLOAD
                              ? URL.createObjectURL(newBrandImage)
                              : newBrandImage
                          }
                          alt="preview"
                        />
                      )}

                      <div className="buttons">
                        <LoadingButton
                          variant="contained"
                          color="primary"
                          onClick={handleBrandImageSave}
                          disabled={brandImageSaveLoading || !newBrandImage}
                          loading={brandImageSaveLoading}
                        >
                          Save
                        </LoadingButton>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </CardContent>
            </Card>
            <Button
              variant="contained"
              color="primary"
              onClick={setCalendarOnClick}
              style={{
                fontSize: "1rem",
                textTransform: "none",
                marginTop: "16px",
                marginBottom: "16px",
              }}
            >
              {brandImageSaveSuccess ? "Continue" : "Skip"}
            </Button>
          </div>
        )}

        {activeStep === 2 && (
          <div>
            <Card variant="outlined" className="card-set centerContent-set">
              <CardContent className="card-content">
                <FormGroup>
                  <Typography
                    variant="h5"
                    style={{ marginBottom: 12, marginTop: 12 }}
                  ></Typography>

                  <TextField
                    className="setting-field"
                    fullWidth
                    label="Calendar Name"
                    helperText="The name of your calendar."
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    style={{ marginBottom: 12 }}
                    onChange={(event) => {
                      dispatch({
                        type: "update-name",
                        name: event.target.value,
                      });
                    }}
                    required
                  />

                  <FormGroup>
                    <FormControl
                      className="setting-field"
                      style={{ marginTop: 8, marginBottom: 12 }}
                      sx={{
                        width: "100%",
                        "& .MuiTextField-root": { width: "100%" },
                      }}
                    ></FormControl>
                  </FormGroup>

                  <TextField
                    className="setting-field"
                    style={{ marginTop: 8, marginBottom: 12 }}
                    label="Description"
                    helperText="Additional details or specific instructions that will be displayed on your booking page."
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    onClick={handleDescriptionClick}
                    value={state.calDescription} // Use 'value' instead of 'defaultValue'
                    multiline
                    rows={3}
                    onChange={(event) => {
                      dispatch({
                        type: "update-cal-description",
                        calDescription: event.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </CardContent>
            </Card>

            <Typography variant="h5"></Typography>
            {/* Add your content for step 3 */}
            <Button
              variant="contained"
              color="primary"
              onClick={saveSettings}
              style={{
                fontSize: "1rem",
                textTransform: "none",
                marginTop: "16px",
                marginBottom: "16px",
              }}
            >
              Complete Setup
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
