import moment from "moment-timezone";
import { Autocomplete, TextField } from "@mui/material";
import * as React from "react";

const getTimezones = () => {
  const timeZones = moment.tz.names();
  const offsetTmz = [];
  for (let i in timeZones) {
    let tz = timeZones[i];
    let timeZoneOption = {
      label: `${tz} (${moment().tz(tz).format("h:mmA")})`,
      value: tz,
    };
    offsetTmz.push(timeZoneOption);
  }
  return offsetTmz;
};

export const TZPicker = ({ defaultValue, userSelectTimezone }) => {
  // TODO - use memo
  const timezones = getTimezones();
  return (
    <Autocomplete
      options={timezones}
      disableClearable
      getOptionLabel={(option) => option.label}
      name="timezone"
      defaultValue={timezones.find((x) => x.value === defaultValue)}
      onChange={(e, v) => {
        return userSelectTimezone(v.value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            style: {
              fontSize: "12px",
            },
          }}
          fullWidth
          label={"Timezone"}
          style={{ width: '225px' }}
        />
      )}
    />
  );
};
