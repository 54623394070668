import * as React from "react";
import {
  Button,
  Alert,
  Snackbar,
  Typography,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Divider,
  Box,
  AppBar,
  Toolbar,
  CssBaseline,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  CreateSharp,
  ShareSharp,
  EditSharp,
  DeleteSharp,
  LaunchSharp,
  MenuSharp,
} from "@mui/icons-material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/system";
import "./MyCalendars.css";
import { getCookie } from "../Utils";
import { FB_IMAGE_WIX } from "../constants";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { useMediaQuery } from "@mui/material";

export default function BasicCard({
  calendarName,
  description,
  brandImageUrl,
  blockSize,
  onClick,
  setDeleted,
  slug,
  setCalendars,
  remaining,
  googleCalendarColor,
  source,
}) {
  const deleteCalendar = async () => {
    if (remaining === 1) {
      alert("You cannot delete your primary calendar.");
      return;
    }

    // eslint-disable-next-line
    const didConfirm = confirm(
      "Are you sure you want to delete this calendar?"
    );
    if (didConfirm) {
      const res = await fetch(`api/calendar/delete/${slug}`);
      await res.json();
      setDeleted(true);
      setCalendars((calendars) => calendars.filter((c) => c.slug !== slug));
    }
  };

  const handleColorChange = async (event) => {
    const color = event.target.value;
    setSelectedColor(color);
    await fetch(`/api/calendar/change-color/${slug}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": getCookie("csrftoken"),
      },
      body: JSON.stringify({
        color: Number(color) ? color : undefined,
      }),
    });
  };

  const ColorOption = styled("div")(({ color }) => ({
    width: "15px",
    height: "15px",
    backgroundColor: color,
    border: "1px solid rgba(0, 0, 0, 0.1)", // Adjust the border size and color as desired
  }));

  const colors = [
    // 0 isn't a valid Google Color but need it for equality to work
    { label: "Default", color: "#039BE5", googleColor: "0" },
    { label: "Lavender", color: "#7986CB", googleColor: "1" },
    { label: "Sage", color: "#33B679", googleColor: "2" },
    { label: "Grape", color: "#8E24AA", googleColor: "3" },
    { label: "Flamingo", color: "#E67C73", googleColor: "4" },
    { label: "Banana", color: "#F6C026", googleColor: "5" },
    { label: "Tangerine", color: "#F5511D", googleColor: "6" },
    { label: "Peacock", color: "#039BE5", googleColor: "7" },
    { label: "Graphite", color: "#616161", googleColor: "8" },
    { label: "Blueberry", color: "#3F51B5", googleColor: "9" },
    { label: "Basil", color: "#0B8043", googleColor: "10" },
    { label: "Tomato", color: "#D60000", googleColor: "11" },
  ];

  const [selectedColor, setSelectedColor] = React.useState(
    googleCalendarColor || "0"
  );

  const navigate = useNavigate();
  return (
    <Card
      sx={{
        maxWidth: 305,
        margin: 4,
        color: "light-gray",
        position: "relative",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
      }}
    >
      {/*<CardContent> //style={{ textAlign: 'center', display: 'inline-block' }}>*/}
      <CardContent>
        <Typography
          variant="h5"
          component="div"
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            paddingTop: 25,
          }}
        >
          {calendarName}
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {description}
        </Typography>
        <Typography
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          Connected to {source || "Primary"}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {blockSize}
        </Typography>
        <img height={40} src={brandImageUrl || FB_IMAGE_WIX} alt="brand img" />
      </CardContent>
      <CardActions>
        <Button
          style={{ textTransform: "none" }}
          size="large"
          onClick={onClick}
          variant="outlined"
          startIcon={<ShareSharp />}
        >
          Share
        </Button>
        <Button
          style={{ textTransform: "none" }}
          size="large"
          variant="outlined"
          startIcon={<EditSharp />}
          onClick={() => navigate(`calendar/edit/${slug}`)}
        >
          Edit
        </Button>

        <div style={{ position: "absolute", top: 5, right: 5, paddingTop: 0 }}>
          <Button
            size="medium"
            style={{ textTransform: "none" }}
            onClick={() => window.open(`book/${slug}`, "_blank")}
            startIcon={<LaunchSharp />}
            target="_blank"
          >
            View
          </Button>
        </div>
        <div className="colorPicker">
          <FormControl style={{ display: "flex" }}>
            <Select
              sx={{ ".MuiOutlinedInput-notchedOutline": { border: 0 } }}
              value={selectedColor}
              onChange={handleColorChange}
              size="small"
              MenuProps={{
                PaperProps: {
                  style: {
                    width: "300px",
                  },
                },
              }}
            >
              {colors.map((color) => (
                <MenuItem
                  key={color.label}
                  value={color.googleColor}
                  style={{ display: "inline-block" }}
                >
                  <ColorOption color={color.color} className="rounded-box" />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Button
          onClick={deleteCalendar}
          size="large"
          variant="outlined"
          style={{ padding: "8px" }}
        >
          <DeleteSharp />
        </Button>
      </CardActions>
    </Card>
  );
}

export const Setup = ({ user }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [copied, setCopy] = React.useState(false);
  const [deleted, setDeleted] = React.useState(false);
  const [slug, setSlug] = React.useState("");
  const [myCalendars, setMyCalendars] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [paySuccess, setPaySuccess] = React.useState(searchParams.get("paid"));

  const copyLinkToClipboard = (slug) => () => {
    setCopy(true);
    navigator.clipboard.writeText(`freeblockscal.com/book/${slug}`);
  };
  React.useEffect(() => {
    const getMyCalendars = async () => {
      const res = await fetch("/api/calendar/list");
      const json = await res.json();
      setSlug(json.primary_slug);
      setMyCalendars(json.calendars);
    };

    getMyCalendars()
      .then(() => setLoading(false))
      .catch(() => {
        setLoading(false);
        alert(
          "Something went wrong. Try reloading the page or e-mailing info@freeblocksapp.com for more info."
        );
      });
  }, []);

  const chunkedCalendars = [];
  for (let i = 0; i < myCalendars.length; i += 3) {
    const chunk = myCalendars.slice(i, i + 3);
    chunkedCalendars.push(chunk);
  }
  const renderRows = chunkedCalendars.map((calendar, idx) => {
    const cards = calendar.map((r) => {
      return (
        <Grid item key={r.slug}>
          <BasicCard
            remaining={myCalendars.length}
            slug={r.slug}
            description={r.description}
            brandImageUrl={r.calendar_image_url || user.brand_image_url}
            calendarName={r.name}
            googleCalendarColor={r.settings.google_calendar_color}
            blockSize={r.blockSize}
            onClick={copyLinkToClipboard(r.slug)}
            setDeleted={setDeleted}
            setCalendars={setMyCalendars}
            source={r.sources[0] ? r.sources[0].name : "Primary"}
          />
        </Grid>
      );
    });

    return (
      <Grid key={idx} container sx={{ flexGrow: 1 }}>
        {cards}
      </Grid>
    );
  });
  if (loading) {
    return <CircularProgress style={{ marginTop: 24 }} />;
  }

  const drawerWidth = 240;

  return (
    <div style={{ marginTop: 48 }}>
      <Snackbar
        open={paySuccess}
        autoHideDuration={6000}
        onClose={() => setPaySuccess(false)}
      >
        <Alert
          onClose={() => setPaySuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Upgraded to Free Blocks Premium!
        </Alert>
      </Snackbar>
      <Snackbar
        open={copied}
        autoHideDuration={6000}
        onClose={() => setCopy(false)}
      >
        <Alert
          onClose={() => setCopy(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Copied booking link to clipboard.
        </Alert>
      </Snackbar>
      <Snackbar
        open={deleted}
        autoHideDuration={6000}
        onClose={() => setDeleted(false)}
      >
        <Alert
          onClose={() => setDeleted(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Successfully deleted calendar.
        </Alert>
      </Snackbar>
      <div style={{ marginTop: 16 }}>
        <Button
          variant="contained"
          color="primary"
          endIcon={<CreateSharp />}
          onClick={() => {
            navigate("/calendar/create");
          }}
          style={{
            textTransform: "none",
            fontStyle: "bold",
            marginLeft: isMobile ? "0" : "240px",
          }}
          className="create-calendar-btn"
        >
          Create a new calendar
        </Button>
      </div>
      <div
        style={{
          display: "grid",
          justifyContent: "space-evenly",
          alignItems: "center",
          margin: "auto",
          marginLeft: isMobile ? "0" : "240px",
        }}
      >
        {/*  <Grid item>*/}
        {/*    <p>hi</p>*/}
        {renderRows}
        {/*  </Grid>*/}
      </div>
      {/*<div>Settings (TODO - Integration w/ GCal, Working Hours, Branding)</div>*/}
    </div>
  );
};
