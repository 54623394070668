export const convertStringEmailsToArray = (emailString) => {
  if (!emailString) {
    return;
  }

  return emailString.split(",").map((email) => email.trim());
};

export const convertArrayEmailsToString = (emailList) => {
  if (!emailList) {
    return;
  }

  return emailList.join(", ");
};

export const validateEmails = (emailList) => {
  if (!emailList) {
    return false;
  }

  const emails = convertStringEmailsToArray(emailList);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  for (const email of emails) {
    if (!emailRegex.test(email)) {
      return false;
    }
  }

  return true;
};

export const getCookie = (name) => {
  const cookieValue = document.cookie.match(
    "(^|;)\\s*" + name + "\\s*=\\s*([^;]+)"
  );
  return cookieValue ? cookieValue.pop() : "";
};
