import { FormControlLabel, Grid } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Button } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { BreakMaker, RangeMaker } from "./RangeMaker";
import * as React from "react";
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';


const adapter = new AdapterDayjs();
const DAYS_OF_THE_WEEK = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const getDateAtHour = (hour, minutes = 0) => {
  const d = new Date();
  d.setHours(hour);
  d.setMinutes(minutes);
  return d;
};

export const BreakAdder = ({ dispatch, slotMetadataRaw }) => {
  const slotMetadata = [];
  if (slotMetadataRaw) {
    slotMetadataRaw.forEach((s) => {
      slotMetadata.push({
        component: (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <BreakMaker adapter={adapter} dispatch={dispatch} slot={s} />
          </LocalizationProvider>
        ),
        breakId: s.breakId,
        metadata: s,
      });
    });
  }

  const addedSlots = slotMetadata;
  const addSlot = () => {
    const breakId = Math.random().toString(36).slice(2);
    const slotToUse = {
      breakId,
      start: "12:00PM",
      end: "1:00PM",
    };
    const newSlots = addedSlots.concat([
      {
        component: (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <BreakMaker
              adapter={adapter}
              dispatch={dispatch}
              slot={slotToUse}
            />
          </LocalizationProvider>
        ),
        breakId,
        metadata: slotToUse,
      },
    ]);
    dispatch({
      type: "add-break-slot",
      break_ranges: newSlots.map((slot) => slot.metadata),
    });
  };

  const removeSlot = (breakId) => {
    dispatch({
      type: "remove-break-slot",
      break_ranges: addedSlots
        .filter((slot) => slot.breakId !== breakId)
        .map((slot) => slot.metadata),
    });
  };

  return (
    <div>
      {addedSlots.map((s) => {
        return (
          <Grid key={s.breakId} container direction="row" alignItems="center" spacing={0.5}>
            <Grid>
              <p>Every day from</p>
            </Grid>
            <Grid item md>
              {s.component}
            </Grid>
            <Grid
              onClick={() => removeSlot(s.breakId)}
              item
              style={{ cursor: 'pointer' }}
            >
              <DeleteSharpIcon />
            </Grid>
          </Grid>
        );
      })}
      <br />
      <Button onClick={addSlot}>Add break</Button>
    </div>
  );
};

export const DayWorkingHourForm = ({ dayState, dispatch }) => {
  return (
    <Grid container direction="row" spacing={0.5}>
      <Grid item style={{ width: 150 }}>
        <FormControlLabel
          style={{ display: "flex" }}
          control={
            <Checkbox
              checked={dayState.enabled}
              onChange={(val) =>
                dispatch({
                  type: "update-day-enabled",
                  enabled: val.target.checked,
                  index: dayState.day,
                })
              }
            />
          }
          label={DAYS_OF_THE_WEEK[dayState.day]}
        />
      </Grid>
      <Grid item md>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <RangeMaker
            adapter={adapter}
            dayState={dayState}
            dispatch={dispatch}
            getDateAtHour={getDateAtHour}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
};
