import * as React from "react";
import { Typography } from "@mui/material";
import { Check } from "@mui/icons-material";
import { Login } from "../Login";
import "./BookingPage.css";

export const Confirmation = ({ name, dateText }) => {
  return (
    <div className="confirmation">
      <Check color="success" fontSize="large" />
      <Typography variant="h5">You are booked with {name}.</Typography>
      <Typography>
        <i>{dateText}</i>
      </Typography>
      <Typography variant="h6" style={{ marginTop: 16 }}>
        Want a free booking page in seconds? Free Blocks has your back.
      </Typography>
      <div
        style={{
          paddingTop: 4,
          paddingBottom: 24,
          width: "275px",
          margin: "0 auto",
        }}
      >
        <Login />
      </div>
      <div>
        <a
          data-testid="linkElement"
          href="https://workspace.google.com/marketplace/app/free_blocks/820412152333"
          target="_self"
          rel="noreferrer noopener"
          className="j7pOnl"
        >
          <wow-image
            id="img_comp-la4a3cn6"
            className="HlRz5e BI8PVQ"
            data-image-info='{"containerId":"comp-la4a3cn6","displayMode":"fill","targetWidth":302,"targetHeight":45,"isLQIP":false,"imageData":{"width":1549,"height":231,"uri":"8f0bbb_7b0f45df32c046038eea8666854b4f2b~mv2.png","name":"v2.png","displayMode":"fill","quality":{"unsharpMask":{"radius":0.66,"amount":1,"threshold":0.01}}}}'
            data-bg-effect-name=""
            data-has-ssr-src=""
            data-src="https://static.wixstatic.com/media/8f0bbb_7b0f45df32c046038eea8666854b4f2b~mv2.png/v1/fill/w_604,h_90,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/v2.png"
          >
            <img
              src="https://static.wixstatic.com/media/8f0bbb_7b0f45df32c046038eea8666854b4f2b~mv2.png/v1/fill/w_604,h_90,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/v2.png"
              alt="v2.png"
              style={{ width: 250 }}
            />
          </wow-image>
        </a>
      </div>
      <div>
        <a
          data-testid="linkElement"
          href="https://freeblocks.slack.com/apps/A0457NRC0TV-free-blocks?tab=more_info"
          target="_self"
          rel="noreferrer noopener"
          className="j7pOnl"
          style={{ marginTop: 20 }}
        >
          <img
            src="https://static.wixstatic.com/media/b1d9ea_08aa9ceee45343488f753d7697dea417~mv2.png/v1/fill/w_384,h_112,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/SLACK.png"
            alt="SLACK.png"
            style={{ width: 200 }}
          />
        </a>
      </div>
    </div>
  );
};
