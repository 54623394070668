import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { Button, Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { CalendarBranding } from "./CalendarBranding";
import { Confirmation } from "./Confirmation";
import { getCookie } from "../Utils.js";
import moment from "moment-timezone";
import { Card, CardContent } from "@mui/material";
import "./BookingForm.css";

const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const convertTZ = (date, tzString) => {
  const time = moment(date).format("YYYY-MM-DDTHH:mm:ss");
  const converted = moment(time).tz(tzString);

  return converted;
};

const emailsAreValid = (emails) => {
  if (!emails) {
    return true;
  }

  // Split the string into an array of email addresses
  const emailArray = emails.split(",");

  // Regular expression pattern for validating email addresses
  const emailPattern = /^\s*[\w+\-.]+@[a-z\d\-]+(\.[a-z\d\-]+)*\.[a-z]+\s*$/i;

  // Loop through each email address and check if it's valid
  for (let i = 0; i < emailArray.length; i++) {
    const email = emailArray[i].trim(); // Trim whitespace around each email address

    // Check if the email address matches the pattern
    if (!emailPattern.test(email)) {
      return false; // Invalid email address found
    }
  }

  return true; // All email addresses are valid
};

const convertAMPMStrToISODate = (dateBaseRaw, amPMString, timezone) => {
  const dateBase = convertTZ(dateBaseRaw, timezone);
  // Parse hour and minute components from string
  const timeParts = amPMString.match(/^(\d+):(\d+)(AM|PM)$/);
  const hour = parseInt(timeParts[1], 10);
  const minute = parseInt(timeParts[2], 10);

  // Convert hour to 24-hour format
  let hour24 = hour;
  if (timeParts[3] === "PM" && hour !== 12) {
    hour24 += 12;
  }

  dateBase.set("hour", hour24);
  dateBase.set("minute", minute);

  return dateBase.toISOString();
};

const BookingForm = ({
  fullName,
  imageUrl,
  bookingSlug,
  timezone,
  freeBlock,
  formDate,
  eventName: presetEventName,
  eventDescription: presetEventDescription,
  descriptionEditable,
}) => {
  const [booked, setBooked] = React.useState(false);
  const [eventName, setEventName] = React.useState();
  const [description, setEventDescription] = React.useState();
  const [emails, setEmails] = React.useState();
  const [guestEmails, setGuestEmails] = React.useState();
  const [guestEmailError, setGuestEmailError] = React.useState();
  const [name, setYourName] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const hasEventName = Boolean(presetEventName);

  const dateText = `${
    MONTH_NAMES[formDate.getMonth()]
  } ${formDate.getDate()}, ${freeBlock.start} - ${freeBlock.end} (${timezone})`;
  const descriptionShouldBeReadOnly =
    !!presetEventDescription && !descriptionEditable;

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setLoading(true);
    const blockStart = convertAMPMStrToISODate(
      formDate,
      freeBlock.start,
      timezone
    );
    const blockEnd = convertAMPMStrToISODate(formDate, freeBlock.end, timezone);

    const res = await fetch("/api/book", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": getCookie("csrftoken"),
      },
      body: JSON.stringify({
        event: presetEventName || eventName,
        description: descriptionShouldBeReadOnly
          ? presetEventDescription || description || ""
          : description || "",
        emails,
        guestEmails,
        bookingSlug,
        blockStart,
        blockEnd,
        name,
        clientTimezone: timezone,
      }),
    });

    try {
      const jsonResult = await res.json();
      if (jsonResult.redirect_url) {
        window.location = jsonResult.redirect_url;

        // Wait for redirect to take effect
        await new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve();
          }, 3000);
        });
      }

      setBooked(true);
      setLoading(false);
    } catch (error) {
      alert(
        "An error occurred. Please confirm all form information is entered correctly. If this error persists, contact info@freeblocksapp.com for more information."
      );
      setLoading(false);
    }
  };

  if (booked) {
    return (
      // Google Translate removes text nodes and interrupts React render
      // https://github.com/facebook/react/issues/11538#issuecomment-390386520
      <span>
        <Confirmation
          style={{ marginTop: 32 }}
          name={fullName}
          dateText={dateText}
        />
      </span>
    );
  }
  return (
    <Card className="booking-form">
      <CardContent>
        <div>
          <CalendarBranding
            fullName={fullName}
            imageUrl={imageUrl}
            timezone={timezone}
          />
          <Grid sx={{ flexGrow: 1, marginTop: 2 }} spacing={0.5}>
            <Grid>
              <div>{dateText}</div>
            </Grid>
            <Grid>
              <Box
                className="box-form"
                component="form"
                // sx={{
                //   "& > :not(style)": { m: 1, width: "50ch" },
                // }}
                //noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <div style={{ marginTop: "8px" }}>
                  <TextField
                    fullWidth
                    label="Event Name"
                    id={hasEventName ? "" : "outlined-basic"}
                    variant={hasEventName ? "filled" : "outlined"}
                    defaultValue={presetEventName || ""}
                    readOnly={hasEventName}
                    style={{ marginBottom: "8px" }}
                    onChange={(event) => setEventName(event.target.value)}
                    InputProps={{
                      readOnly: hasEventName,
                    }}
                    required={!hasEventName}
                  />
                </div>
                {(!hasEventName ||
                  descriptionEditable ||
                  presetEventDescription) && (
                  <div>
                    <TextField
                      fullWidth
                      defaultValue={presetEventDescription || ""}
                      label="Event Description"
                      id="filled-basic"
                      readOnly={descriptionShouldBeReadOnly}
                      variant={
                        descriptionShouldBeReadOnly ? "filled" : "outlined"
                      }
                      style={{ marginBottom: "8px", marginTop: "8px" }}
                      multiline
                      onChange={(event) =>
                        setEventDescription(event.target.value)
                      }
                      InputProps={{
                        readOnly: descriptionShouldBeReadOnly,
                      }}
                      required
                    />
                  </div>
                )}
                <div>
                  <TextField
                    fullWidth
                    label="Name"
                    variant="outlined"
                    style={{ marginBottom: "8px" }}
                    onChange={(event) => setYourName(event.target.value)}
                    required
                  />
                </div>
                <div>
                  <TextField
                    fullWidth
                    label="Email"
                    type={"email"}
                    variant="outlined"
                    style={{ marginBottom: "8px" }}
                    onChange={(event) => setEmails(event.target.value)}
                    required
                  />
                </div>
                <div>
                  <TextField
                    fullWidth
                    error={Boolean(guestEmailError)}
                    helperText={
                      guestEmailError
                        ? "Please enter valid comma-separated e-mails"
                        : ""
                    }
                    label="Comma-Separated Email Guests (optional)"
                    variant="outlined"
                    style={{ marginBottom: "8px" }}
                    onChange={(event) => {
                      const emails = event.target.value;
                      const emailError = !emailsAreValid(emails);
                      setGuestEmailError(emailError);
                      setGuestEmails(event.target.value);
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "16px",
                  }}
                >
                  <div style={{ flex: "0" }}>
                    <Button
                      variant="outlined"
                      style={{ fontSize: "1rem" }}
                      color="primary"
                      onClick={() => window.location.reload()}
                    >
                      Back
                    </Button>
                  </div>
                  <div style={{ flex: "6", textAlign: "center" }}>
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      style={{ fontSize: "1rem" }}
                      disabled={loading || guestEmailError}
                      loading={loading}
                    >
                      <span>Book Event</span>
                    </LoadingButton>
                  </div>
                  <div style={{ flex: "1", textAlign: "left" }}></div>
                </div>
              </Box>
            </Grid>
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
};

export default BookingForm;
