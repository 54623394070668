import * as React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Divider,
  CssBaseline,
  Avatar,
  Typography,
} from "@mui/material";
import InboxIcon from "@mui/icons-material/Inbox";
import EventIcon from "@mui/icons-material/Event";
import BuildIcon from "@mui/icons-material/Build";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import { Button, Dialog, DialogContent, DialogActions } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { FB_IMAGE_WIX } from "./constants";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { StarSharp } from "@mui/icons-material";

export default function ButtonAppBar(props) {
  const isMobile = useMediaQuery("(max-width:600px)");
  const location = useLocation();
  const pathname = location.pathname;
  const isSettingPage =
    pathname.includes("calendar/edit") || pathname.includes("calendar/create");

  const user = props.user;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  if (props.isBooking || !user || !user.first_name) {
    return "";
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToProfiles = () => {
    setAnchorEl(null);
    navigate("/profile");
  };

  const goToMyCalendars = () => {
    setAnchorEl(null);
    navigate("/");
  };

  const goToMyIntegrations = () => {
    setAnchorEl(null);
    navigate("/integrations");
  };

  const goToBookings = () => {
    setAnchorEl(null);
    navigate("/bookings");
  };

  const goToHelp = () => {
    window.open(
      "https://www.freeblocksapp.com/resources/categories/how-to",
      "_blank"
    );
  };

  const logout = async () => {
    await fetch("/api/logout");
    window.location = "/";
  };

  const handleModalOpen = () => {
    if (window.klaviyo) {
      window.klaviyo.track("Clicked Upgrade Button");
    }
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const drawerWidth = 240;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* Sidebar */}

      <div style={{ display: isMobile || isSettingPage ? "none" : "block" }}>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Toolbar />
          <Divider />
          <List>
            <ListItem disablePadding>
              <ListItemButton
                onClick={goToMyCalendars}
                style={{
                  backgroundColor: pathname === "/" ? "#e0e0e0" : "transparent",
                }}
              >
                <ListItemIcon sx={{ marginRight: -3 }}>
                  <EventIcon />
                </ListItemIcon>
                <ListItemText primary="My Calendars" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={goToBookings}
                style={{
                  backgroundColor:
                    pathname === "/bookings" ? "#e0e0e0" : "transparent",
                }}
              >
                <ListItemIcon sx={{ marginRight: -3 }}>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Bookings" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={goToMyIntegrations}
                style={{
                  backgroundColor:
                    pathname === "/integrations" ? "#e0e0e0" : "transparent",
                }}
              >
                <ListItemIcon sx={{ marginRight: -3 }}>
                  <IntegrationInstructionsIcon />
                </ListItemIcon>
                <ListItemText primary="Integrations" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={goToHelp}>
                <ListItemIcon sx={{ marginRight: -3 }}>
                  <HelpOutlineIcon />
                </ListItemIcon>
                <ListItemText primary="How To" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={goToProfiles}
                style={{
                  backgroundColor:
                    pathname === "/profile" ? "#e0e0e0" : "transparent",
                }}
              >
                <ListItemIcon sx={{ marginRight: -3 }}>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primary="Account" />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List>{/* Add more items if necessary */}</List>
        </Drawer>
      </div>

      {/* Main Content */}
      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "#f0f0f0",
          }}
        >
          <Toolbar>
            <img
              src={FB_IMAGE_WIX}
              alt="Free Blocks Logo"
              style={{ height: "50px", cursor: "pointer" }}
              onClick={goToMyCalendars}
            />
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1 }}
            />
            {/* Upgrade Button */}
            {user.tier !== "Free" && (
              <div
                style={{ marginLeft: "-140px", padding: "10px" }}
                onClick={goToProfiles}
              >
                <WorkspacePremiumIcon fontSize="large" color="primary" />
              </div>
            )}
            {(user.tier === "Free" || user.tier === "Trial") && (
              <div style={{ marginLeft: "-140px", padding: "10px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleModalOpen}
                  endIcon={<StarSharp />}
                  style={{ textTransform: "none" }}
                >
                  {user.tier === "Free" ? "Upgrade" : "Free Trial"}
                </Button>
                <Dialog open={open} onClose={handleModalClose}>
                  <DialogContent
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      overflowX: "hidden",
                    }}
                  >
                    <div style={{ width: "250%" }}>
                      <stripe-pricing-table
                        pricing-table-id="prctbl_1NLwswHw5rkQneVSQ4CUHddZ"
                        publishable-key="pk_live_51NL7cQHw5rkQneVSlGInLKx0ohxgg1mhff0sfUuTaFkooAyvTKMObiGV0kp710dDujsmjZZ77lXMkpsbRFhWjeXS00Ikkdvd0U"
                        customer-email={user.email}
                      ></stripe-pricing-table>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleModalClose}>Close</Button>
                  </DialogActions>
                </Dialog>
              </div>
            )}

            {/* User Avatar and Menu */}
            {user && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  src="/path/to/profile/avatar"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  {user.first_name[0]}
                  {user.last_name[0]}
                </Avatar>
                <Typography
                  style={{
                    marginLeft: "8px",
                    cursor: "pointer",
                    color: "#757575",
                  }}
                  onClick={handleMenu}
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                >
                  {user.first_name} {user.last_name}
                </Typography>
                {/* User Menu */}
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={goToProfiles}>
                    <ListItemIcon>
                      <AccountCircleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Account" />
                  </MenuItem>
                  <MenuItem onClick={goToMyCalendars}>
                    <ListItemIcon>
                      <EventIcon />
                    </ListItemIcon>
                    <ListItemText primary="My Calendars" />
                  </MenuItem>
                  <MenuItem onClick={goToBookings}>
                    <ListItemIcon>
                      <InboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Bookings" />
                  </MenuItem>
                  <MenuItem onClick={goToMyIntegrations}>
                    <ListItemIcon>
                      <IntegrationInstructionsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Integrations" />
                  </MenuItem>
                  <MenuItem onClick={goToHelp}>
                    <ListItemIcon>
                      <HelpOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary="Help" />
                  </MenuItem>
                  <MenuItem onClick={logout}>
                    <ListItemIcon>
                      <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary="Log out" />
                  </MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </AppBar>
        {/* Main Content Section */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: "background.default",
            p: 3,
            marginLeft: "240px",
          }}
        >
          {/* Content of the main section */}
        </Box>
      </Box>
    </Box>
  );
}
