import React from "react";
import { Typography } from "@mui/material";
import { Link } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import { Grid } from "@mui/material";
import KlaviyoImg from "../images/KLAVIYO.png";
import ExchangeImg from "../images/MSFT.png";
import GoogleImage from "../images/GCAL.png";
import ZoomImage from "../images/ZOOM.png";
import { getCookie } from "../Utils";
import CircularProgress from "@mui/material/CircularProgress";
import { Check } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";
const IntegrationCard = ({ title, imageURL, status, enabled }) => {
  return (
    <Card sx={{ width: 250, height: 215, margin: 4, color: "light-gray" }}>
      <CardHeader title={title} />
      <CardMedia component="img" height="75" image={imageURL} />
      <CardActions>
        {/*<Button size="small" onClick={() => console.log("todo")}>*/}
        {/*  Integrate*/}
        {/*</Button>*/}
        {/*<Button size="small" onClick={() => console.log("todo")}>*/}
        {/*  Edit*/}
        {/*</Button>*/}
        <CardContent>{status}</CardContent>
        {enabled && <Check color="info" />}
      </CardActions>
    </Card>
  );
};

export const Integrations = () => {
  const [failed, setFailed] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [googleEnabled, setGoogleEnabled] = React.useState();
  const [msftEnabled, setMSFTEnabled] = React.useState();
  const [zoomEnabled, setZoomEnabled] = React.useState();
  const [klaviyoEnabled, setKlaviyoEnabled] = React.useState();
  const isMobile = useMediaQuery("(max-width:600px)");

  React.useEffect(() => {
    const fetchIntegrations = async () => {
      const res = await fetch(`/api/user/integrations`, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
        },
      });

      if (res.status >= 400) {
        setFailed(true);
        return;
      }

      const integrationsJSON = await res.json();
      setGoogleEnabled(integrationsJSON.google);
      setMSFTEnabled(integrationsJSON.microsoft);
      setZoomEnabled(integrationsJSON.zoom);
      setKlaviyoEnabled(integrationsJSON.klaviyo);

      setLoading(false);
    };

    fetchIntegrations();
  }, []);

  if (failed) {
    return (
      <div style={{ margin: "auto", marginTop: 16, width: "65%" }}>
        <Typography>Page failed to load. Try refreshing.</Typography>
      </div>
    );
  }

  if (loading) {
    return <CircularProgress style={{ marginTop: 16 }} />;
  }

  return (
    <div style={{ marginTop: 48, marginLeft: isMobile ? "0" : "240px" }}>
      <Typography variant="h4">Integrations</Typography>
      <div
        style={{
          display: "grid",
          justifyContent: "space-evenly",
          alignItems: "center",
          margin: "auto",
        }}
      >
        <Grid
          container
          spacing={1}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <IntegrationCard
            title={"Google Calendar"}
            imageURL={GoogleImage}
            status={<Link href="/integrations/google">Configure</Link>}
            enabled={googleEnabled}
            // status={googleEnabled ? "Integrated" : "Not Integrated"}
          />
          <IntegrationCard
            title="Zoom"
            imageURL={ZoomImage}
            status={<Link href="/integrations/zoom">Configure</Link>}
            enabled={zoomEnabled}
          />
          <IntegrationCard
            title={"Klaviyo"}
            imageURL={KlaviyoImg}
            status={<Link href="/integrations/klaviyo">Configure</Link>}
            enabled={klaviyoEnabled}
          />
          <IntegrationCard
            title={"Microsoft 365"}
            imageURL={ExchangeImg}
            status={msftEnabled ? "Integrated" : "Coming Soon"}
            enabled={msftEnabled}
          />
        </Grid>
      </div>
    </div>
  );
};
