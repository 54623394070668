import "../App.css";
import * as React from "react";
import { CalendarBranding } from "./CalendarBranding";
import TextField from "@mui/material/TextField";
import { Helmet } from "react-helmet";
import moment from "moment-timezone";
import { TZPicker } from "./TZPicker";
import {
  StaticDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Launch } from "@mui/icons-material";
import { Typography, Grid, Box, Link } from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import BookingForm from "./BookingForm";
import { useParams, useLocation } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { MobileBookingPage } from "./MobileBooking";
import { Card, CardContent } from "@mui/material";
import { FB_IMAGE_WIX } from "../constants";
import "./BookingPage.css";

const BadSlug = () => {
  return (
    <Typography
      style={{
        width: "50%",
        textAlign: "center",
        margin: "auto",
        marginTop: 32,
      }}
    >
      This link does not connect to any valid calendar; you may have mistyped it
      or the user who provided it has made changes to their account. For more
      information, please contact{" "}
      <a href="mailto:info@freeblocksapp.com">info@freeblocksapp.com</a>.
    </Typography>
  );
};

const getDateAsKey = (date) => {
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  if (month < 10) {
    month = `0${month}`;
  }

  if (day < 10) {
    day = `0${day}`;
  }

  return `${year}-${month}-${day}`;
};

const convertToNewTZ = (date, endTz) => {
  const timeFormat = "h:mmA";
  const dateFormat = "YYYY-MM-DD";

  const newDate = moment(date).tz(endTz);
  return {
    date: newDate.format(dateFormat),
    time: newDate.format(timeFormat),
  };
};
const transformFreeTimesDataOnClient = (freeTimesData, selectedZone) => {
  // My suggestion here for tomorrow:
  // Server gives you just array of [[start, end], [start, end]]
  // Client does all the dictionary grouping
  const allFreeTimes = freeTimesData.free_times;

  const clientTimezone = selectedZone || moment.tz.guess();

  const transformedData = {};

  allFreeTimes.forEach(({ start, end }) => {
    const newStart = convertToNewTZ(start, clientTimezone);
    const newEnd = convertToNewTZ(end, clientTimezone);

    if (newStart.date !== newEnd.date) {
      // Do not support day crossovers
      return undefined;
    }

    const hasData = transformedData[newStart.date];
    if (hasData) {
      transformedData[newStart.date].push({
        start: newStart.time,
        end: newEnd.time,
      });
    } else {
      transformedData[newStart.date] = [
        {
          start: newStart.time,
          end: newEnd.time,
        },
      ];
    }
  });

  freeTimesData.convertedFreeTimes = transformedData;
  return freeTimesData;
};

const BookingPage = () => {
  let { slug } = useParams();
  const searchParams = new URLSearchParams(useLocation().search);
  const isMobile = useMediaQuery("(max-width:600px)");

  const [loading, setLoading] = React.useState(true);
  const [timesLoading, setTimesLoading] = React.useState(false);
  const [activeButtonPage, setActiveButtonPage] = React.useState(0);
  const [freeTimes, setFreeTimes] = React.useState();
  const [blockForForm, setForm] = React.useState();
  const [value, setValue] = React.useState(null);
  const [rawDate, setRawDate] = React.useState(new Date());
  const [daySelected, setDaySelected] = React.useState(
    getDateAsKey(new Date())
  );
  const [touchedDate, setTouchedDate] = React.useState(false);
  const [timezone, setTimezone] = React.useState(moment.tz.guess());
  const [activeButton, setActiveButton] = React.useState();

  const handleButtonClick = async (duration) => {
    setTimesLoading(true);
    setActiveButton(duration);

    // Go to the server to compute the new times
    const res = await fetch(
      `/api/free-times-from-slug/${slug}?duration=${duration}`
    );
    if (res.status > 500) {
      alert(
        "Our server encountered an error. Please email info@freeblocksapp.com for more information."
      );
    } else {
      const serverFreeTimesData = await res.json();
      const convertedFreeTimesData = transformFreeTimesDataOnClient(
        serverFreeTimesData,
        timezone
      );
      setFreeTimes(convertedFreeTimesData);

      // Because we paginate times, also reset the button page.
      setActiveButtonPage(0);
    }

    setTimesLoading(false);
  };

  const getDurationButtons = () => {
    return freeTimes.is_multi_duration
      ? [
          { label: "15 mins", value: "15" },
          { label: "30 mins", value: "30" },
          { label: "1 hour", value: "60" },
        ]
          .filter((data) => freeTimes.possible_durations.includes(data.value))
          .map((duration, index) => (
            <Button
              key={index}
              onClick={() => handleButtonClick(duration.value)}
              variant={
                activeButton === duration.value ? "contained" : "outlined"
              }
              color="primary"
            >
              {duration.label}
            </Button>
          ))
      : [];
  };

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const durationParam = urlParams.get("duration");
    if (durationParam) {
      setActiveButton(durationParam);
    }
  }, []);

  React.useEffect(() => {
    const get = async () => {
      let duration = Number(searchParams.get("duration") || 60);
      if (![60, 30, 15].includes(duration)) {
        duration = 30; // default to 30, do not accept invalid values
      }
      const res = await fetch(
        `/api/free-times-from-slug/${slug}?duration=${duration}`
      );

      let serverFreeTimesData;
      try {
        serverFreeTimesData = await res.json();
      } catch (e) {
        alert(
          "Our server encountered an error. Please email info@freeblocksapp.com for more information."
        );
        return;
      }

      if (serverFreeTimesData.is_bad_slug) {
        setLoading(false);
        setFreeTimes(serverFreeTimesData);
      } else {
        const convertedFreeTimesData =
          transformFreeTimesDataOnClient(serverFreeTimesData);
        setFreeTimes(convertedFreeTimesData);

        if (serverFreeTimesData.is_multi_duration) {
          setActiveButton(duration);
        }

        setLoading(false);
      }
    };

    get();
  }, []);

  if (loading) {
    return <CircularProgress style={{ marginTop: 48 }} />;
  }

  if (freeTimes.is_bad_slug) {
    return <BadSlug />;
  }

  const freeTimeChoices = freeTimes.convertedFreeTimes[daySelected] || [];
  let fullName = `${freeTimes.first_name} ${freeTimes.last_name}`;
  if (!freeTimes.last_name) {
    fullName = freeTimes.first_name;
  }

  // Apply override if page has it
  const hasPublicName = freeTimes.usePublicTitle;
  if (hasPublicName) {
    fullName = freeTimes.page_name;
  }

  const imageUrl = freeTimes.image_url;

  let calDescription = freeTimes.calDescription;

  const userSelectTimezone = (newTimezone) => {
    setTimezone(newTimezone);
    setFreeTimes((ft) =>
      transformFreeTimesDataOnClient(freeTimes, newTimezone)
    );
  };

  let buttons = freeTimeChoices.map((freeBlock) => {
    return (
      <div key={freeBlock.start + freeBlock.end} style={{ margin: "5px 0" }}>
        <Helmet>
          <title>{`${fullName}'s Calendar`}</title>
          <meta
            name="description"
            content={`Schedule time with ${fullName} through Free Blocks, the world's leading calendar management system.`}
          />
          <meta
            name="og:description"
            content={`Schedule time with ${fullName} through Free Blocks, the world's leading calendar management system.`}
          />
          <meta property="og:title" content={`${fullName}'s Calendar`} />

          <meta property="og:image" content={imageUrl || FB_IMAGE_WIX} />
        </Helmet>
        <Button
          style={{
            width: "177px", // set a fixed width
            height: "50px", // set a fixed height
            padding: "12px", // adjust padding as needed
            fontSize: "1rem",
          }}
          onClick={() => setForm(freeBlock)}
        >
          {freeBlock.start + " - " + freeBlock.end}
        </Button>
      </div>
    );
  });

  if (blockForForm) {
    return (
      <BookingForm
        formDate={rawDate}
        fullName={fullName}
        imageUrl={imageUrl}
        descriptionEditable={freeTimes.descriptionEditable}
        calDescription={freeTimes.calDescription}
        bookingSlug={freeTimes.booking_slug}
        timezone={timezone}
        freeBlock={blockForForm}
        eventName={freeTimes.event_name}
        eventDescription={freeTimes.event_description}
      />
    );
  }

  const disableDatesBasedOnSettings = (dateObj) => {
    const date = dateObj["$d"];
    const key = getDateAsKey(date);

    return !freeTimes.convertedFreeTimes[key];
  };

  const chunkedButtons = [];
  for (let i = 0; i < buttons.length; i += 5) {
    const chunk = buttons.slice(i, i + 5);
    chunkedButtons.push(chunk);
  }
  if (!buttons.length) {
    buttons = <p>No meetings available.</p>;
    chunkedButtons.push(buttons);
  }

  const maxButton = chunkedButtons.length;
  let paginateFunction = () => setActiveButtonPage((b) => b + 1);
  const lastPage = activeButtonPage === maxButton - 1;
  if (lastPage) {
    // This technically just takes you back to beginning
    paginateFunction = () => setActiveButtonPage(0);
  }

  if (isMobile) {
    return (
      <MobileBookingPage
        imageUrl={imageUrl}
        timezone={timezone}
        fullName={fullName}
        freeTimes={freeTimes}
        disableDatesBasedOnSettings={disableDatesBasedOnSettings}
        value={value}
        setValue={setValue}
        setRawDate={setRawDate}
        setActiveButtonPage={setActiveButtonPage}
        setDaySelected={setDaySelected}
        getDateAsKey={getDateAsKey}
        daySelected={daySelected}
        touchedDate={touchedDate}
        setTouchedDate={setTouchedDate}
        chunkedButtons={chunkedButtons}
        activeButtonPage={activeButtonPage}
        lastPage={lastPage}
        paginateFunction={paginateFunction}
      />
    );
  }

  return (
    <Card className="calendar-card">
      <CardContent>
        <div className="App" style={{ marginTop: 24 }}>
          <div className="Content">
            <Grid container columns={2} justifyContent="center">
              <Grid>
                <CalendarBranding
                  fullName={fullName}
                  imageUrl={imageUrl}
                  calDescription={calDescription}
                  timezone={timezone}
                  showTimezoneChoose={true}
                  userSelectTimezone={userSelectTimezone}
                />
              </Grid>
            </Grid>
            <div style={{ width: 650, marginTop: 44, margin: "auto" }}>
              <Grid container columns={8} spacing={2} style={{ marginTop: 24 }}>
                {/*<Grid xs={4}>*/}
                {/*  Hi*/}
                {/*</Grid>*/}
                <Grid xs={4} sx={{ flexGrow: 1 }} spacing={0.5}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StaticDatePicker
                      displayStaticWrapperAs="desktop"
                      shouldDisableDate={disableDatesBasedOnSettings}
                      value={value}
                      onChange={(newValue) => {
                        const date = newValue["$d"];
                        setValue(newValue);
                        setRawDate(date);
                        setActiveButtonPage(0);
                        setTouchedDate(true);
                        setDaySelected(getDateAsKey(date));
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      className="custom-static-datepicker"
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid xs={4}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginBottom: "10px",
                      "& > *": {
                        m: 1,
                      },
                      whiteSpace: "nowrap", // Prevent text wrapping
                      "& .MuiButtonBase-root": {
                        width: "100%", // Set width to '100%'
                      },
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {/* Set width to '100%' and center content */}
                      <div style={{ maxWidth: "200px", width: "100%" }}>
                        {/* Add a maxWidth and width of '100%' */}
                        {freeTimes.is_multi_duration && (
                          <div>
                            <label className="label-styling">
                              Meeting Duration
                            </label>
                            <br />
                            <div style={{ display: "flex", width: "100%" }}>
                              <ButtonGroup
                                size="small"
                                aria-label="small button group"
                                style={{ flex: 1 }}
                              >
                                {getDurationButtons()}
                              </ButtonGroup>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <TZPicker
                        defaultValue={timezone}
                        userSelectTimezone={userSelectTimezone}
                      />
                    </div>
                  </Box>
                  {timesLoading && (
                    <CircularProgress style={{ marginTop: 16 }} />
                  )}

                  {!timesLoading &&
                    chunkedButtons.map((buttonChunk, i) => {
                      if (i !== activeButtonPage) {
                        return null;
                      }
                      return (
                        <ButtonGroup
                          key={i}
                          orientation="vertical"
                          aria-label="vertical contained button group"
                          // variant="contained"
                        >
                          {buttonChunk}
                        </ButtonGroup>
                      );
                    })}
                  {!timesLoading && chunkedButtons.length > 1 && (
                    <Button
                      style={{
                        width: "177px", // set a fixed width
                        height: "50px", // set a fixed height
                        padding: "12px", // adjust padding as needed
                      }}
                      onClick={paginateFunction}
                    >
                      {lastPage ? "See Previous" : "See More"}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <a
                href="https://www.freeblockscal.com"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{ marginTop: 16 }}
                  height="48"
                  src={FB_IMAGE_WIX}
                  alt="brand img"
                />
              </a>
              <div style={{ marginLeft: 8 }}>
                <Link
                  href={
                    freeTimes.isOwnPage
                      ? `https://www.freeblockscal.com/calendar/edit/${slug}`
                      : "https://www.freeblockscal.com"
                  }
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none", marginBottom: 8 }}
                >
                  {freeTimes.isOwnPage
                    ? "This is your booking page - edit it here."
                    : "Build your own free booking page"}
                  <Launch style={{ padding: 4, fontSize: 12 }} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default BookingPage;
