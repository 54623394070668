import { Button, Link, Typography } from "@mui/material";
import { ShareSharp, EditSharp, LaunchSharp } from "@mui/icons-material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import "./MyCalendars.css";
import "./BookingCard.css";
import { FB_IMAGE_WIX } from "../constants";

export default function BasicCard({ bookingDatum }) {
  const { data } = bookingDatum;
  return (
    <Card
      className="bookingCard"
      sx={{
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
      }}
    >
      <CardContent className="bookingContent">
        <Typography variant="h6" component="div" className="bookingContent">
          Name: {data.summary}
        </Typography>
        <Typography component="div" className="bookingContent">
          Created by: {data.booker_name}
        </Typography>
        <Typography className="bookingContent" color="text.secondary">
          {bookingDatum.description}
        </Typography>
        <Typography className="bookingContent">
          Time: {bookingDatum.formatted_datetime}
        </Typography>
        <Typography className="bookingContent" color="text.secondary">
          Created: {bookingDatum.created} via{" "}
          <Link
            href={`/calendar/edit/${bookingDatum.booking_page.slug}`}
            target="_blank"
          >
            booking page {bookingDatum.booking_page.name}
          </Link>
          .
        </Typography>
      </CardContent>
      <CardActions>
        <div className="cardEmail">
          <Button
            size="medium"
            variant="outlined"
            style={{ textTransform: "none" }}
            startIcon={<EditSharp />}
            onClick={() => window.open(`mailto:${data.emails}`, "_blank")}
          >
            Email
          </Button>
        </div>
        <div style={{ position: "absolute", top: 5, right: 5, paddingTop: 0 }}>
          <Button
            size="small"
            style={{ textTransform: "none" }}
            onClick={() => window.open(data.htmlLink, "_blank")}
            startIcon={<LaunchSharp />}
            target="_blank"
          >
            View
          </Button>
        </div>
      </CardActions>
    </Card>
  );
}
