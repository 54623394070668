import * as React from "react";
import { Grid, Typography } from "@mui/material";
import { captureException } from "@sentry/react";
import { FB_IMAGE_WIX } from "./constants";

export const ErrorPage = () => {
  const now = String(new Date());
  const traceId = Math.random().toString(36).slice(2);

  React.useEffect(() => {
    captureException(new Error(traceId), {
      traceId,
      page: window.location,
    });
  });

  return (
    <div
      style={{
        margin: "auto",
        width: "75%",
        marginTop: "40px",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Typography variant="h5">
        Our fault; the app crashed. Try refreshing the page. If this error
        persists, email{" "}
        <a href="mailto:info@freeblocksapp.com">info@freeblocksapp.com</a> and
        we will get you back up and running.
      </Typography>
      <Typography style={{ marginTop: 8 }}>
        Details: App crash occurred at {now}. Trace ID: <code>{traceId}</code>.
        Our team has been notified and please do not hesitate to contact us for
        support.
      </Typography>
      <Grid item style={{ marginTop: 15 }}>
        <img height={100} src={FB_IMAGE_WIX} />
      </Grid>
    </div>
  );
};
