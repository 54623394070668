import { validateEmails } from "../Utils";

export const settingsReducer = (state, action) => {
  switch (action.type) {
    case "update-name": {
      const { name } = action;

      return {
        ...state,
        name,
      };
    }
    case "update-slug": {
      const { slug } = action;

      return {
        ...state,
        slug,
      };
    }
    case "update-booking-link": {
      const { bookingLink } = action;

      return {
        ...state,
        bookingLink,
      };
    }

    case "update-meeting-reminder": {
      const { meetingReminder } = action;

      return {
        ...state,
        meetingReminder,
      };
    }

    case "update-calendar-source": {
      const { source } = action;

      return {
        ...state,

        // Eventually, support multiple, but support single just for now
        sources: [source],

        // For now, assume source is always destination
        // We can get more customizable in future versions
        destinations: [source],
      };
    }

    case "update-reminder-duration": {
      const { reminderDuration } = action;

      return {
        ...state,
        reminderDuration,
      };
    }

    case "update-event-name": {
      const { eventName } = action;

      return {
        ...state,
        eventName,
      };
    }

    case "update-cal-description": {
      const { calDescription } = action;

      return {
        ...state,
        calDescription,
      };
    }

    case "update-event-description": {
      const { eventDescription } = action;

      return {
        ...state,
        eventDescription,
      };
    }

    case "update-redirect-url": {
      const { redirect_url } = action;

      return {
        ...state,
        redirect_url,
      };
    }

    case "update-range": {
      const { index, start_hour, start_minute, end_hour, end_minute } = action;
      const day = state.day_ranges[index];

      return {
        ...state,
        day_ranges: [
          ...state.day_ranges.slice(0, index),
          {
            ...day,
            ranges: [
              {
                start_hour: start_hour,
                start_minute: start_minute,
                end_hour: end_hour,
                end_minute: end_minute,
              },
            ],
          },
          ...state.day_ranges.slice(index + 1),
        ],
      };
    }
    case "set-state": {
      const { state: newState } = action;
      return { ...newState, loading: false };
    }
    case "update-day-enabled": {
      const { index, enabled } = action;
      const day = state.day_ranges[index];
      return {
        ...state,
        day_ranges: [
          ...state.day_ranges.slice(0, index),
          {
            ...day,
            enabled: enabled,
          },
          ...state.day_ranges.slice(index + 1),
        ],
      };
    }

    case "add-break-slot": {
      const { break_ranges } = action;

      return {
        ...state,
        break_ranges,
      };
    }

    case "remove-break-slot": {
      const { break_ranges } = action;

      return {
        ...state,
        break_ranges,
      };
    }

    case "mutate-break-slot": {
      const { break_ranges } = state;
      const { newSlot } = action;
      const newBreakRanges = break_ranges.map((b) => {
        if (b.breakId === newSlot.breakId) {
          return newSlot;
        }

        return b;
      });
      return {
        ...state,
        break_ranges: newBreakRanges,
      };
    }

    case "update-shared-calendars": {
      const { sharedCalendars } = action;

      return {
        ...state,
        sharedCalendars,
      };
    }

    case "update-calendar-image": {
      const { calendarImage } = action;

      return {
        ...state,
        calendarImage,
      };
    }

    case "update-optional-attendees": {
      const { optionalAttendees } = action;
      if (validateEmails(optionalAttendees)) {
        return {
          ...state,
          optionalAttendees,
        };
      }
      return state;
    }

    case "update-event-desc-editable": {
      const { descriptionEditable } = action;
      return {
        ...state,
        descriptionEditable,
      };
    }

    case "use-public-title": {
      const { usePublicTitle } = action;
      return {
        ...state,
        usePublicTitle,
      };
    }

    default:
      return state;
  }
};
