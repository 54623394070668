import { Alert, Snackbar } from "@mui/material";
import * as React from "react";

export const SettingsAlert = ({ message, messageSetter, severity }) => (
  <Snackbar
    anchorOrigin={{ vertical: "top", horizontal: "center" }}
    open={Boolean(message)}
    autoHideDuration={6000}
    onClose={() => messageSetter(false)}
  >
    <Alert
      onClose={() => messageSetter(false)}
      severity={severity}
      sx={{ width: "100%" }}
    >
      {message}
    </Alert>
  </Snackbar>
);
