import React, { useState } from "react";
import { Button, Typography, Box, Link } from "@mui/material";
import TextField from "@mui/material/TextField";
import { getCookie } from "../Utils";
import { useNavigate } from "react-router-dom";

export const KlaviyoIntegrationSetup = () => {
  const [klaviyoPrivateKey, setKlaviyoPrivateKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [isIntegrated, setIsIntegrated] = useState(false);

  React.useEffect(() => {
    const getIsKlaviyoIntegrated = async () => {
      const res = await fetch("/api/integrations/klaviyo/is-integrated");
      const json = await res.json();
      setIsIntegrated(json.isIntegrated);
    };

    getIsKlaviyoIntegrated();
    setLoading(false);
  }, []);

  const navigate = useNavigate();
  const setKeyOnClick = async (evt) => {
    // evt.preventDefault();
    if (!klaviyoPrivateKey) {
      return;
    }

    setLoading(true);

    const res = await fetch("/api/integrations/klaviyo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": getCookie("csrftoken"),
      },
      body: JSON.stringify({
        klaviyoPrivateKey,
      }),
    });

    try {
      const json = await res.json();
      setIsIntegrated(true);
      alert("Awesome! You are all set up in Klaviyo.");
    } catch (error) {
      console.log(error);
      alert(
        "Something went wrong. Please contact info@freeblocksapp.com for further assistance."
      );
    }
    setLoading(false);
  };

  return (
    <div style={{ maxWidth: 600, margin: "auto", padding: 48 }}>
      <Typography variant="h4">
        {isIntegrated ? "You are set up!" : "Let's get you set up in Klaviyo."}
      </Typography>
      {!isIntegrated && (
        <Typography variant="h5">
          Enter your Klaviyo private key.&nbsp;
          <Link href="https://help.klaviyo.com/hc/en-us/articles/7423954176283">
            You can find instructions on how to create a full access private key
            here.
          </Link>
        </Typography>
      )}
      {isIntegrated && (
        <Typography variant="h5">
          You're integrated with Klaviyo! &nbsp;
          <Link href="https://help.klaviyo.com/hc/en-us/articles/7423954176283">
            If you want to replace your private key, you can do so below.
          </Link>
        </Typography>
      )}
      <Box
        component="form"
        // sx={{
        //   "& > :not(style)": { m: 1, width: "50ch" },
        // }}
        //noValidate
        autoComplete="off"
        onSubmit={setKeyOnClick}
      >
        <div style={{ marginTop: "16px" }}>
          <TextField
            fullWidth
            label="Klaviyo Private Key"
            id="outlined-basic"
            variant="outlined"
            style={{ marginBottom: "8px" }}
            onChange={(event) => setKlaviyoPrivateKey(event.target.value)}
            required
          />
        </div>
        <div
          style={{
            justifyContent: "center",
            marginTop: "16px",
          }}
        >
          <div style={{ textAlign: "center", margin: "auto" }}>
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              onClick={setKeyOnClick}
              type="submit"
              style={{ fontSize: "1rem", margin: "auto" }}
            >
              Save
            </Button>
          </div>
          <div style={{ flex: "1", textAlign: "left" }}></div>
        </div>
      </Box>

      <Typography style={{ marginTop: 16 }}>
        Once you're set up, use events with the metric name "Free Blocks -
        Booked Time" to leverage the full power of the Klaviyo platform.
      </Typography>
    </div>
  );
};
