import { Typography } from "@mui/material";
import * as React from "react";
import BasicCard from "./BookingsCard";
import CircularProgress from "@mui/material/CircularProgress";
import { useMediaQuery } from "@mui/material";
import "./BookingCard.css";

export const Bookings = () => {
  const [loading, setLoading] = React.useState(true);
  const [bookedEvents, setBookedEvents] = React.useState();
  const isMobile = useMediaQuery("(max-width:600px)");

  React.useEffect(() => {
    const getBookedEvents = async () => {
      const res = await fetch("/api/booked-events/list");
      const json = await res.json();
      setBookedEvents(json.events);
    };

    getBookedEvents()
      .then(() => setLoading(false))
      .catch((e) => {
        setLoading(false);
        alert(
          "Something went wrong. Try reloading the page or e-mailing info@freeblocksapp.com for more info."
        );
      });
  }, []);

  if (loading) {
    return <CircularProgress style={{ marginTop: 24 }} />;
  }

  if (!bookedEvents || bookedEvents.length === 0) {
    return (
      <div>
        <Typography
          style={{ marginTop: 48, marginLeft: isMobile ? "0" : "240px" }}
          variant="h5"
        >
          You don't have any bookings yet.
        </Typography>
        <Typography
          style={{ marginTop: 16, marginLeft: isMobile ? "0" : "240px" }}
          variant="h6"
        >
          Visit one of your booking pages to create a booking.
        </Typography>
      </div>
    );
  }

  return (
    <div className="cardBody">
      {bookedEvents.map((bookingDatum) => {
        return <BasicCard bookingDatum={bookingDatum} key={bookingDatum.id} />;
      })}
    </div>
  );
};
