import * as React from "react";
import { getCookie } from "./Utils";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { ExpandMore, Http, UploadFile } from "@mui/icons-material";
import { FB_IMAGE_WIX } from "./constants";
import { LoadingButton } from "@mui/lab";
import { SettingsAlert } from "./calendar_owner/SettingsAlerts";
import { useMediaQuery } from "@mui/material";

// Brand image inputs options
const FILE_UPLOAD = "FILE_UPLOAD";
const URL_UPLOAD = "URL_UPLOAD";

export const Account = (props) => {
  const [info, setInfo] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [brandImageSaveLoading, setBrandImageSaveLoading] =
    React.useState(false);
  const [firstName, setFirstName] = React.useState();
  const [lastName, setLastName] = React.useState();
  const [brandImageUploadLocation, setBrandImageUploadLocation] =
    React.useState(FILE_UPLOAD);
  const [brandImage, setBrandImage] = React.useState("");
  const [newBrandImage, setNewBrandImage] = React.useState(null);
  const [brandImageSaveError, setBrandImageSaveError] = React.useState("");
  const [brandImageSaveSuccess, setBrandImageSaveSuccess] = React.useState("");
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleManageSubscription = () => {
    window.location.href =
      "https://billing.stripe.com/p/login/7sI9Ee8AXgg2dDG6oo";
  };

  React.useEffect(() => {
    const getAccountData = async () => {
      const stuff = await fetch("/api/user-info");
      const json = await stuff.json();
      setInfo(json);
      setFirstName(json.first_name);
      setLastName(json.last_name);
      setBrandImage(json.brand_image_url);
      setLoading(false);
    };

    getAccountData();
  }, []);

  if (loading) {
    return null;
  }

  const handleBrandImageUploadLocationChange = (event, newInput) => {
    if (newInput !== null) {
      setBrandImageUploadLocation(newInput);
      setNewBrandImage(null);
    }
  };

  const onFileImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setNewBrandImage(event.target.files[0]);
    }
  };

  const handleResetName = async () => {
    // Perform save logic here for resetting name
    const res = await fetch(`/api/user/reset-name`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": getCookie("csrftoken"),
      },
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
      }),
    });

    if (res.status >= 400) {
      alert("An error occurred. Please try again");
    } else {
      alert("Your profile has been updated.");
    }
  };

  const handleBrandImageSave = async () => {
    // Perform save logic to store brand image as file in S3 or as a URL
    setBrandImageSaveLoading(true);
    try {
      // TODO: Should we daisy chain the requests in .then()?
      if (brandImageUploadLocation === FILE_UPLOAD) {
        // Get the presigned URL from the server so that we can post the file in the S3 bucket
        const res = await fetch("api/user/branding/get-s3-post-url", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
          },
          body: JSON.stringify({
            file_name: newBrandImage.name,
            file_type: newBrandImage.type,
          }),
        });
        if (res.status >= 400) {
          console.log(res);
          throw res.statusText;
        }

        // Once we have it, pull out the fields and store it as form data
        // Then upload the file and data to the URL given
        const presignedUrlData = await res.json();
        const postData = new FormData();
        for (const key in presignedUrlData.fields) {
          postData.append(key, presignedUrlData.fields[key]);
        }
        postData.append("file", newBrandImage);

        const s3UploadRes = await fetch(presignedUrlData.url, {
          method: "POST",
          body: postData,
        });

        if (s3UploadRes.status >= 400) {
          throw s3UploadRes.statusText;
        }

        // Once we know this has succeeded, save the file name to the user
        const saveToDbRes = await fetch("api/user/branding/add-file-name", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
          },
          body: JSON.stringify({
            file_name: newBrandImage.name,
          }),
        });

        if (saveToDbRes.status >= 400) {
          throw saveToDbRes.statusText;
        } else {
          setBrandImageSaveSuccess("Your brand image has been updated.");
        }
      } else {
        // Post endpoint that just saves the image URL to the user
        const saveToDbRes = await fetch("api/user/branding/add-file-url", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
          },
          body: JSON.stringify({
            file_url: newBrandImage,
          }),
        });

        if (saveToDbRes.status >= 400) {
          throw saveToDbRes.statusText;
        } else {
          setBrandImageSaveSuccess("Your brand image has been updated.");
        }
      }
      // On success, reload the window so that if a new image has been
      // saved, it will be displayed on the other pages as well
      window.location.reload();
    } catch (err) {
      setBrandImageSaveError(err);
    } finally {
      setBrandImageSaveLoading(false);
    }
  };

  const daysName = info.days_remaining_trial == 1 ? "day" : "days";
  return (
    <div className="centerContent" style={{ marginTop: 48 }}>
      <Card variant="outlined" className="card">
        <CardContent>
          <h2>Account Info</h2>
          {info.tier == "Trial" && (
            <h4
              style={{ fontStyle: "italic" }}
            >{`${info.days_remaining_trial} ${daysName} remaining on trial`}</h4>
          )}
          <div className="container">
            <TextField
              label="First Name"
              defaultValue={info.first_name}
              size="medium"
              required
              className="profileTextField"
              onChange={(e) => setFirstName(e.target.value)}
            />
            <br />
            <TextField
              label="Last Name"
              defaultValue={info.last_name}
              size="medium"
              required
              className="profileTextField"
              onChange={(e) => setLastName(e.target.value)}
            />
            <br />
            <TextField
              label="Email"
              value={info.email}
              size="medium"
              className="profileTextField"
              readOnly
              required
            />
            <br />
          </div>
          <div className="buttons">
            {info.tier === "Premium" && (
              <Button
                variant="contained"
                onClick={handleManageSubscription}
                style={{ textTransform: "none" }}
              >
                Subscription
              </Button>
            )}

            {/*{info.tier !== "Premium" && (*/}
            {/*  <Button variant="contained" onClick={handleManageSubscription} style={{ textTransform: 'none'}}>*/}
            {/*    Upgrade*/}
            {/*  </Button>*/}
            {/*)}*/}

            {/* Use LoadingButton instead to indicate status? */}
            <Button
              className="saveButton"
              variant="contained"
              color="primary"
              onClick={handleResetName}
              style={{ textTransform: "none" }}
            >
              Save
            </Button>
          </div>
          <br />
          <div className="optionLinks">
            <p>
              <a href="mailto:info@freeblocksapp.com">Delete my account</a>
            </p>
          </div>
        </CardContent>
      </Card>

      <SettingsAlert
        message={brandImageSaveError}
        messageSetter={setBrandImageSaveError}
        severity="error"
      />
      <SettingsAlert
        message={brandImageSaveSuccess}
        messageSetter={setBrandImageSaveSuccess}
        severity="success"
      />

      <Card variant="outlined" className="card">
        <CardContent>
          <h2>Brand Image</h2>
          <p className="profileText centerAlign">
            Your brand image will replace the default FreeBlocks branding and
            will be shown on all of your calendars.
          </p>
          {brandImage ? (
            <img
              className="brandImage centerAlign"
              src={brandImage}
              alt="current brand"
            />
          ) : (
            <>
              <p className="profileText centerAlign">
                A brand image has not been uploaded. We will default to
                FreeBlocks branding.
              </p>
              <img
                className="brandImage centerAlign"
                src={FB_IMAGE_WIX}
                alt="freeblocks brand img"
              />
            </>
          )}
          <br />
          <div className="container centerAlign">
            <Accordion
              defaultExpanded={!Boolean(brandImage)}
              disableGutters
              elevation={0}
              style={{
                maxWidth: "400px",
                padding: 0,
                margin: 0,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{
                  maxWidth: "400px",
                  padding: 0,
                  margin: 0,
                  color: "blue",
                  "& .MuiAccordionSummary-content": {
                    width: "auto",
                    flexGrow: 0,
                    justifyContent: "center",
                    margin: 0,
                  },
                }}
              >
                Upload New Image
              </AccordionSummary>
              <AccordionDetails
                sx={{ maxWidth: "400px", padding: 0, margin: 0 }}
              >
                <p className="accordionText centerAlign">
                  Upload an image from your file system or from a URL:
                </p>
                <ToggleButtonGroup
                  className="centerAlign"
                  value={brandImageUploadLocation}
                  exclusive
                  onChange={handleBrandImageUploadLocationChange}
                  aria-label="brand image input"
                >
                  <ToggleButton value={FILE_UPLOAD}>
                    <UploadFile titleAccess="Upload file from your system" />
                  </ToggleButton>
                  <ToggleButton value={URL_UPLOAD}>
                    <Http />
                  </ToggleButton>
                </ToggleButtonGroup>
                <br />
                <br />
                {brandImageUploadLocation === FILE_UPLOAD ? (
                  <TextField
                    size="medium"
                    type="file"
                    required
                    className="profileTextField"
                    inputProps={{
                      accept: "image/png, image/jpeg, image/jpg, image/svg",
                    }}
                    onChange={onFileImageChange}
                  />
                ) : (
                  <TextField
                    size="medium"
                    type="url"
                    placeholder="https://imagelocation.com"
                    required
                    className="profileTextField"
                    inputProps={{ maxLength: 300 }}
                    onChange={(e) => setNewBrandImage(e.target.value)}
                  />
                )}
                <br />
                <br />
                {newBrandImage && (
                  <img
                    className="brandImage centerAlign"
                    src={
                      brandImageUploadLocation === FILE_UPLOAD
                        ? URL.createObjectURL(newBrandImage)
                        : newBrandImage
                    }
                    alt="preview"
                  />
                )}

                <div className="buttons">
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={handleBrandImageSave}
                    disabled={brandImageSaveLoading || !newBrandImage}
                    loading={brandImageSaveLoading}
                  >
                    Save
                  </LoadingButton>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </CardContent>
      </Card>
      <br />
    </div>
  );
};
