import "./App.css";
import * as React from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import ButtonAppBar from "./ButtonAppBar";
import BookingPage from "./public_facing/BookingPage";
import { useLocation } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Login } from "./Login";
import { Setup } from "./calendar_owner/MyCalendars";
import { UserContext } from "./UserContext";
import { Settings } from "./calendar_owner/Settings";
import { Integrations } from "./calendar_owner/Integrations";
import { SetupWizard } from "./calendar_owner/SetupWizard";
import { KlaviyoIntegrationSetup } from "./calendar_owner/KlaviyoIntegration";
import { Account } from "./Account";
import "./profilePage.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { ErrorPage } from "./ErrorPage";
import TagManager from "react-gtm-module";
import { FB_IMAGE_WIX } from "./constants";
import { ZoomIntegrationSetup } from "./calendar_owner/ZoomIntegration";
import { GoogleIntegrationSetup } from "./calendar_owner/GoogleIntegration";
import { Bookings } from "./calendar_owner/Bookings";

const tagManagerArgs = {
  gtmId: "AW-11018865416",
};

TagManager.initialize(tagManagerArgs);

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    console.error(error, info.componentStack);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallback;
    }

    return this.props.children;
  }
}

const Layout = (props) => {
  return (
    <div className="loginAlign">
      <Card>
        <CardContent className="loginCard" style={{ padding: "4em" }}>
          <img
            style={{ marginTop: -20, marginBottom: 16 }}
            height="48"
            src={FB_IMAGE_WIX}
          />
          <Typography style={{ fontSize: "20px" }}>
            Welcome to Free Blocks
          </Typography>
          <Typography style={{ fontSize: "20px" }}>
            Sign up or login to continue
          </Typography>
          <Login props={props} />
        </CardContent>

        <div
          style={{
            fontSize: "0.8em",
            display: "flex",
            justifyContent: "space-between",
            padding: "1em 0",
            textAlign: "left",
            marginLeft: "10px",
            marginRight: "10px",
          }}
        >
          <a
            href="https://www.freeblocksapp.com/privacy"
            style={{ color: "grey", textDecoration: "none" }}
          >
            Privacy Policy
          </a>
          <a
            href="mailto:info@freeblocksapp.com"
            style={{ color: "grey", textDecoration: "none" }}
          >
            Support
          </a>
        </div>
      </Card>
    </div>
  );
};

const PageLayout = ({ user, isBooking, name }) => {
  if (!isBooking && user && !user.first_name) {
    return <SetupWizard />;
  }

  return !isBooking && !user ? (
    <Layout user={user} />
  ) : (
    <div>
      <Outlet />
    </div>
  );
};

const UserWrapper = ({ children }) => {
  const [user, setUser] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState();

  React.useEffect(() => {
    const get = async () => {
      const res = await fetch("/api/user");
      if (res.status >= 500) {
        setError(true);
        return;
      }

      const json = await res.json();
      let user = json.user;
      setUser(user);
      if (user) {
        let email = user.email;
        if (window.heap) {
          window.heap.identify(email);
        }
        if (window.klaviyo) {
          window.klaviyo.identify({
            email: user.email,
          });
        }
      }
      setLoading(false);
    };
    get();
  }, []);

  return (
    <UserContext.Provider value={{ user, logout: () => {}, loading, error }}>
      {children}
    </UserContext.Provider>
  );
};

function App() {
  const location = useLocation();
  const isBooking =
    location.pathname.includes("book") &&
    !location.pathname.includes("bookings");

  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <UserWrapper>
        <UserContext.Consumer>
          {(userContext) => {
            if (userContext.error) {
              return <ErrorPage />;
            }

            if (userContext.loading) {
              return (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress style={{ margin: "auto" }} />
                </Box>
              );
            }
            const user = (userContext || {}).user;
            return (
              <div className="App">
                <ButtonAppBar user={user} isBooking={isBooking} />
                <Routes>
                  <Route path="error" element={<ErrorPage />} />
                  <Route
                    path="/"
                    element={<PageLayout user={user} isBooking={isBooking} />}
                  >
                    <Route
                      path="integrations/google"
                      element={<GoogleIntegrationSetup />}
                    />
                    <Route
                      path="integrations/klaviyo"
                      element={<KlaviyoIntegrationSetup />}
                    />
                    <Route
                      path="integrations/zoom"
                      element={<ZoomIntegrationSetup />}
                    />
                    <Route path="integrations" element={<Integrations />} />
                    <Route
                      path="calendar/edit/:slug"
                      element={<Settings userContext={userContext} />}
                    />
                    <Route
                      path="calendar/create"
                      element={
                        <Settings originalCreate userContext={userContext} />
                      }
                    />
                    <Route path="bookings" element={<Bookings user={user} />} />
                    <Route path="profile" element={<Account user={user} />} />
                    <Route path="book/:slug" element={<BookingPage />} />

                    <Route path="/set-up" element={<Setup user={user} />} />
                    {/* Using path="*"" means "match anything", so this route
                    acts like a catch-all for URLs that we don't have explicit
                    routes for. */}
                    <Route path="/" element={<Setup user={user} />} />
                  </Route>
                </Routes>
              </div>
            );
          }}
        </UserContext.Consumer>
      </UserWrapper>
    </ErrorBoundary>
  );
}

export default App;
