import * as React from "react";
import { Typography, Grid } from "@mui/material";
import { FB_IMAGE_WIX } from "../constants";

export const CalendarBranding = ({
  fullName,
  imageUrl,
  timezone,
  calDescription,
  showTimezoneChoose,
  userSelectTimezone,
}) => (
  <Grid container alignItems="center" justifyContent="center" spacing={2}>
    <Grid item>
      <img height={69} src={imageUrl || FB_IMAGE_WIX} alt="brand img" />
    </Grid>
    <Grid item style={{ width: 300 }}>
      <Typography variant="h6" component="h5">
        {fullName}'s Calendar
      </Typography>
      {<i>All times in {timezone}</i>}
    </Grid>
    {calDescription && ( // Check if calDescription has a value
      <Grid
        item
        xs={12}
        style={{ textAlign: "center", width: "150px", wordWrap: "break-word" }}
      >
        {calDescription}
      </Grid>
    )}
  </Grid>
);
