import React, { useState } from "react";
import { Typography, Link, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ZoomImage from "../images/ZOOM.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { SettingsAlert } from "./SettingsAlerts";
import { getCookie } from "../Utils";
export const ZoomIntegrationSetup = () => {
  const [loading, setLoading] = useState(true);

  const [zoomLink, setZoomLink] = useState("");
  const [zoomIntegrations, setZoomIntegrations] = useState([]);
  const [isIntegrated, setIsIntegrated] = useState(false);
  const [removeZoom, setRemoveZoom] = useState(false);

  const getIsZoomIntegrated = async () => {
    const res = await fetch("/api/zoom/get-auth-url");
    const json = await res.json();
    setZoomLink(json.url);
    setZoomIntegrations(json.integrations);
    setIsIntegrated(json.integrations.length > 0);
    setLoading(false);
  };

  const revokeZoomToken = async (data) => {
    setLoading(true);
    await fetch("/api/zoom/revoke-zoom-token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": getCookie("csrftoken"),
      },
      body: JSON.stringify(data),
    });
    await getIsZoomIntegrated();
    setRemoveZoom("Zoom integration successfully removed.");
  };

  React.useEffect(() => {
    getIsZoomIntegrated();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  const message = isIntegrated
    ? "You are integrated with Zoom."
    : "Integrate with Zoom here";
  return (
    <div style={{ maxWidth: 800, margin: "auto", padding: 48 }}>
      <SettingsAlert message={removeZoom} messageSetter={setRemoveZoom} />
      <img src={ZoomImage} />
      {isIntegrated && <p>You are integrated with Zoom.</p>}
      {isIntegrated && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="right">Display Name</TableCell>
                <TableCell align="right">Avatar</TableCell>
                <TableCell align="right">Email</TableCell>
                <TableCell align="right">Timezone</TableCell>
                <TableCell align="right">Meeting URL</TableCell>
                <TableCell align="right">Remove</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {zoomIntegrations.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="right">{row.display_name}</TableCell>
                  <TableCell align="right">
                    <img src={row.pic_url} width={36} />
                  </TableCell>
                  <TableCell align="right">{row.email}</TableCell>
                  <TableCell align="right">{row.timezone}</TableCell>
                  <TableCell align="right">
                    <Link href={row.personal_meeting_url}>Link</Link>
                  </TableCell>
                  <TableCell align="right">
                    <Link
                      style={{ cursor: "pointer" }}
                      onClick={() => revokeZoomToken(row)}
                    >
                      Remove
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <div style={{ marginTop: 24 }}>
        {!isIntegrated && <Link href={zoomLink}>{message}</Link>}
        {isIntegrated && (
          <Typography style={{ marginTop: 16 }}>{message}</Typography>
        )}
      </div>
      {!isIntegrated && (
        <Typography style={{ marginTop: 16 }}>
          Once you're set up, you should see an option to choose your Zoom
          account for any Free Blocks calendar.
        </Typography>
      )}
    </div>
  );
};
