import React, { useState } from "react";
import { Button, Typography, Box, Link, Zoom } from "@mui/material";
import TextField from "@mui/material/TextField";
import { getCookie } from "../Utils";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import GoogleImage from "../images/GCAL.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// https://gist.github.com/ansaso/accaddab0892a3b47d5f4884fda0468b
const GOOGLE_COLORS = {
  kind: "calendar#colors",
  updated: "2012-02-14T00:00:00.000Z",
  calendar: {
    1: {
      background: "#ac725e",
      foreground: "#1d1d1d",
    },
    2: {
      background: "#d06b64",
      foreground: "#1d1d1d",
    },
    3: {
      background: "#f83a22",
      foreground: "#1d1d1d",
    },
    4: {
      background: "#fa573c",
      foreground: "#1d1d1d",
    },
    5: {
      background: "#ff7537",
      foreground: "#1d1d1d",
    },
    6: {
      background: "#ffad46",
      foreground: "#1d1d1d",
    },
    7: {
      background: "#42d692",
      foreground: "#1d1d1d",
    },
    8: {
      background: "#16a765",
      foreground: "#1d1d1d",
    },
    9: {
      background: "#7bd148",
      foreground: "#1d1d1d",
    },
    10: {
      background: "#b3dc6c",
      foreground: "#1d1d1d",
    },
    11: {
      background: "#fbe983",
      foreground: "#1d1d1d",
    },
    12: {
      background: "#fad165",
      foreground: "#1d1d1d",
    },
    13: {
      background: "#92e1c0",
      foreground: "#1d1d1d",
    },
    14: {
      background: "#9fe1e7",
      foreground: "#1d1d1d",
    },
    15: {
      background: "#9fc6e7",
      foreground: "#1d1d1d",
    },
    16: {
      background: "#4986e7",
      foreground: "#1d1d1d",
    },
    17: {
      background: "#9a9cff",
      foreground: "#1d1d1d",
    },
    18: {
      background: "#b99aff",
      foreground: "#1d1d1d",
    },
    19: {
      background: "#c2c2c2",
      foreground: "#1d1d1d",
    },
    20: {
      background: "#cabdbf",
      foreground: "#1d1d1d",
    },
    21: {
      background: "#cca6ac",
      foreground: "#1d1d1d",
    },
    22: {
      background: "#f691b2",
      foreground: "#1d1d1d",
    },
    23: {
      background: "#cd74e6",
      foreground: "#1d1d1d",
    },
    24: {
      background: "#a47ae2",
      foreground: "#1d1d1d",
    },
  },
};

export const GoogleIntegrationSetup = () => {
  const [loading, setLoading] = useState(true);

  const [googleCalendars, setGoogleCalendars] = useState([]);
  const [isIntegrated, setIsIntegrated] = useState(false);

  React.useEffect(() => {
    const getIsGoogleIntegrated = async () => {
      const res = await fetch("/api/calendar_source/list");
      const json = await res.json();
      setGoogleCalendars(json.calendars);
      setIsIntegrated(json.calendars.length > 0);
      setLoading(false);
    };

    getIsGoogleIntegrated();
  }, []);

  if (loading) {
    return <CircularProgress style={{ marginTop: 16 }} />;
  }

  return (
    <div style={{ maxWidth: 800, margin: "auto", padding: 48 }}>
      <img src={GoogleImage} />
      {isIntegrated && <p>You are integrated with Google Calendar</p>}
      {isIntegrated && (
        <p>
          The table below shows you the calendar sources you can use when
          editing a calendar.{" "}
          <a
            href="https://www.freeblocksapp.com/post/connect-any-google-calendar-to-free-blocks"
            target="_blank"
          >
            Please see this help article for details on how to connect a booking
            page to a Google Calendar source.
          </a>
        </p>
      )}
      {isIntegrated && (
        <TableContainer component={Paper} style={{ marginTop: 32 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Name</TableCell>
                <TableCell>Primary?</TableCell>
                <TableCell>Google Color</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {googleCalendars.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{row.name}</TableCell>
                  {/*<TableCell align="right">*/}
                  {/*  <img src={row.pic_url} width={36} />*/}
                  {/*</TableCell>*/}
                  <TableCell>{row.primary ? "Yes" : ""}</TableCell>
                  <TableCell
                    style={{
                      backgroundColor:
                        GOOGLE_COLORS.calendar[row.color_id].background,
                    }}
                  ></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};
