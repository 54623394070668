import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import * as React from "react";
import moment from "moment";

export const RangeMaker = ({ adapter, dayState, dispatch, getDateAtHour }) => {
  return (
    <>
      <TimePicker
        value={adapter.date(
          getDateAtHour(
            dayState.ranges[0].start_hour,
            dayState.ranges[0].start_minute
          )
        )}
        onChange={(val) => {
          dispatch({
            type: "update-range",
            index: dayState.day,
            start_hour: val.$H,
            start_minute: val.$m,
            end_hour: dayState.ranges[0].end_hour,
            end_minute: dayState.ranges[0].end_minute,
          });
        }}
        disabled={!dayState.enabled}
      />
      <TimePicker
        value={adapter.date(
          getDateAtHour(
            dayState.ranges[0].end_hour,
            dayState.ranges[0].end_minute
          )
        )}
        onChange={(val) => {
          dispatch({
            type: "update-range",
            index: dayState.day,
            start_hour: dayState.ranges[0].start_hour,
            start_minute: dayState.ranges[0].start_minute,
            end_hour: val.$H,
            end_minute: val.$m,
          });
        }}
        disabled={!dayState.enabled}
      />
    </>
  );
};

const getSlotOrDefault = (slot, hr, key) => {
  if (slot.start && slot.end) {
    // Thanks ChatGPT
    const timeString = slot[key];

    // Step 3: Extract hour and minute
    const splitIndex = timeString.search(/AM|PM/);
    const time = timeString.slice(0, splitIndex);
    const meridiem = timeString.slice(splitIndex);
    const [hourString, minuteString] = time.split(":");
    let hour = parseInt(hourString, 10);
    const minute = parseInt(minuteString, 10);
    // Step 4: Adjust hour for PM
    if (meridiem === "PM" && hour !== 12) {
      hour += 12;
    }

    // Step 5: Create a new Date object
    const convertedDate = new Date();
    convertedDate.setHours(hour);
    convertedDate.setMinutes(minute);

    return convertedDate;
  }

  const date = new Date();
  date.setHours(hr);
  date.setMinutes(0);
  return date;
};

const dispatchWithConvertedTime = (dispatch, date, slot, key) => {
  const newSlot = {
    ...slot,
    [key]: moment(date).format("h:mmA"),
  };
  dispatch({
    type: "mutate-break-slot",
    newSlot,
  });
};

export const BreakMaker = ({ dispatch, adapter, slot }) => {
  return (
    <>
      <TimePicker
        value={adapter.date(getSlotOrDefault(slot, 12, "start"))}
        onChange={(val) => {
          const date = val["$d"];
          dispatchWithConvertedTime(dispatch, date, slot, "start");
        }}
      />
      <TimePicker
        value={adapter.date(getSlotOrDefault(slot, 13, "end"))}
        onChange={(val) => {
          const date = val["$d"];
          dispatchWithConvertedTime(dispatch, date, slot, "end");
        }}
      />
    </>
  );
};
