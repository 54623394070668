import * as React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import {
  MicrosoftLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";

export const Login = (props) => {
  const openGoogleLoginPage = React.useCallback(() => {
    window.location = "/api/authorize";
  }, []);

  const openMicrosoftLoginPage = React.useCallback(() => {
    window.location = "/api/msft/authorize";
  }, []);

  return (
    <GoogleOAuthProvider clientId="">
      <div>
        <GoogleLoginButton
          // activeStyle={}
          onClick={openGoogleLoginPage}
          label="Sign in with Google"
          style={{ margin: "auto", marginTop: 32, width: "100%" }}
          // disabled={!REA/**/CT_APP_GOOGLE_CLIENT_ID}
        >
          Continue with Google
        </GoogleLoginButton>
        <MicrosoftLoginButton
          onClick={openMicrosoftLoginPage}
          label="Sign in with Google"
          activeStyle={{
            background: "#EFF0EE",
          }}
          style={{
            margin: "auto",
            marginTop: 32,
            background: "white",
            color: "black",
            width: "100%",
            textAlign: "none",
          }}
          // disabled={!REA/**/CT_APP_GOOGLE_CLIENT_ID}
        >
          Continue with Microsoft
          <div style={{ textAlign: "left", fontSize: "15px" }}>(Alpha)</div>
        </MicrosoftLoginButton>
      </div>
    </GoogleOAuthProvider>
  );
};
