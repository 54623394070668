import { CalendarBranding } from "./CalendarBranding";
import {
  LocalizationProvider,
  StaticDatePicker,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import * as React from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./BookingForm.css";

export const MobileBookingPage = ({
  fullName,
  imageUrl,
  freeTimes,
  timezone,
  disableDatesBasedOnSettings,
  value,
  setValue,
  setRawDate,
  setActiveButtonPage,
  setDaySelected,
  getDateAsKey,
  daySelected,
  touchedDate,
  setTouchedDate,
  chunkedButtons,
  activeButtonPage,
  lastPage,
  paginateFunction,
}) => {
  if (touchedDate) {
    return (
      <div
        className="booking-form"
        style={{ paddingTop: "15px", paddingBottom: "15px" }}
      >
        <ArrowBackIosIcon onClick={() => setTouchedDate(false)} />
        <CalendarBranding
          fullName={fullName}
          timezone={timezone}
          imageUrl={imageUrl}
        />
        {chunkedButtons.map((buttonChunk, i) => {
          if (i !== activeButtonPage) {
            return null;
          }
          return (
            <ButtonGroup
              key={i}
              style={{ marginTop: 24 }}
              orientation="vertical"
              aria-label="vertical contained button group"
              // variant="contained"
            >
              {buttonChunk}
            </ButtonGroup>
          );
        })}
        {chunkedButtons.length > 1 && (
          <div>
            <Button
              style={{
                width: "177px", // set a fixed width
                height: "50px", // set a fixed height
                padding: "12px", // adjust padding as needed
              }}
              onClick={paginateFunction}
            >
              {lastPage ? "See Previous" : "See More"}
            </Button>
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className="booking-form"
      style={{ paddingTop: "15px", paddingBottom: "15px" }}
    >
      <CalendarBranding
        fullName={fullName}
        timezone={timezone}
        imageUrl={imageUrl}
      />
      <div style={{ margin: "auto", alignItems: "center" }}>
        {/*<Grid xs={4}>*/}
        {/*  Hi*/}
        {/*</Grid>*/}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDatePicker
            displayStaticWrapperAs="mobile"
            shouldDisableDate={disableDatesBasedOnSettings}
            value={value}
            onChange={(newValue) => {
              const date = newValue["$d"];
              setValue(newValue);
              setRawDate(date);
              setActiveButtonPage(0);
              setDaySelected(getDateAsKey(date));
              setTouchedDate(true);
            }}
            renderInput={(params) => <TextField {...params} />}
            className="custom-static-datepicker"
            slotProps={{ actionBar: { actions: [] } }}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
};
