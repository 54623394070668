import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import GoogleImage from "../images/GCAL.png";
import ZoomImage from "../images/ZOOM.png";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import { useTheme, styled } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
import { Typography, Button, Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router";
import { getCookie } from "../Utils.js";
import { Fab } from "@mui/material";
import Save from "@mui/icons-material/Save";
import Add from "@mui/icons-material/Add";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import WorkIcon from "@mui/icons-material/Work";
import WorkOffIcon from "@mui/icons-material/WorkOff";
import EventNoteIcon from "@mui/icons-material/EventNote";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import TextField from "@mui/material/TextField";
import { BreakAdder, DayWorkingHourForm } from "./DayWorkingHourForm";
import { settingsReducer } from "./settingsReducer";
import "./Settings.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Stack, Chip, Link } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import { FormControlLabel, Switch } from "@mui/material";
import { Dialog, DialogContent, DialogActions } from "@mui/material";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { ExpandMore, Http, UploadFile } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Checkbox from "@mui/material/Checkbox";
import { FB_IMAGE_WIX } from "../constants.js";
import LoadingButton from "@mui/lab/LoadingButton";
import { SettingsAlert } from "./SettingsAlerts";
import { useKeyPress } from "./keyboard";
import {
  validateEmails,
  convertStringEmailsToArray,
  convertArrayEmailsToString,
} from "../Utils.js";

// Brand image inputs options
const FILE_UPLOAD = "FILE_UPLOAD";
const URL_UPLOAD = "URL_UPLOAD";

// Section Names
const GENERAL = "General";
const VIDEO_CONFERENCE_DETAILS = "Video Conference Details";
const EVENT_DETAILS = "Event Details";
const CALENDAR_IMAGE = "Calendar Image";
const GROUP_CALENDAR = "Group Calendar (New!)";
const WORKING_HOURS = "Working Hours";
const BREAKS = "Breaks";

const DURATIONS = [
  { label: "15 mins", value: 15 },
  { label: "30 mins", value: 30 },
  { label: "1 hour", value: 60 },
];

const DEFAULT_STATE = {
  loading: true,
  day_ranges: [
    {
      day: 0,
      ranges: [
        {
          start_hour: 9,
          start_minute: 0,
          end_hour: 17,
          end_minute: 0,
        },
      ],
      enabled: true,
    },
    {
      day: 1,
      ranges: [
        {
          start_hour: 9,
          start_minute: 0,
          end_hour: 17,
          end_minute: 0,
        },
      ],
      enabled: true,
    },
    {
      day: 2,
      ranges: [
        {
          start_hour: 9,
          start_minute: 0,
          end_hour: 17,
          end_minute: 0,
        },
      ],
      enabled: true,
    },
    {
      day: 3,
      ranges: [
        {
          start_hour: 9,
          start_minute: 0,
          end_hour: 17,
          end_minute: 0,
        },
      ],
      enabled: true,
    },
    {
      day: 4,
      ranges: [
        {
          start_hour: 9,
          start_minute: 0,
          end_hour: 17,
          end_minute: 0,
        },
      ],
      enabled: true,
    },
    {
      day: 5,
      ranges: [
        {
          start_hour: 9,
          start_minute: 0,
          end_hour: 17,
          end_minute: 0,
        },
      ],
      enabled: false,
    },
    {
      day: 6,
      ranges: [
        {
          start_hour: 9,
          start_minute: 0,
          end_hour: 17,
          end_minute: 0,
        },
      ],
      enabled: false,
    },
  ],
};

const handleDurationChange = (event, setDurations) => {
  const {
    target: { value },
  } = event;
  setDurations(
    // On autofill we get a stringified value.
    typeof value === "string" ? value.split(",") : value
  );
};

export const Settings = ({ originalCreate, userContext }) => {
  const isPremium = userContext.user.tier !== "Free";
  const profileBrandImage = userContext.user.brand_image_url;

  const testMode = window.location.host.includes("localhost");
  const publishableStripeKey = testMode
    ? "pk_test_51NL7cQHw5rkQneVS7HxRwCvlCJFPk5PaWXUxKT3piDhJ6E3e3ke0lybJko5lFXU0Jz17NBWjvkLJNlh3WzAixqZx00r9l7IYEI"
    : "pk_live_51NL7cQHw5rkQneVSlGInLKx0ohxgg1mhff0sfUuTaFkooAyvTKMObiGV0kp710dDujsmjZZ77lXMkpsbRFhWjeXS00Ikkdvd0U";
  const tableKey = testMode
    ? "prctbl_1NMZ5AHw5rkQneVSIovFMQdx"
    : "prctbl_1NLwswHw5rkQneVSQ4CUHddZ";

  const [create, setCreate] = React.useState(originalCreate);
  const [openModal, setOpenModal] = React.useState(false);
  const [tabValue, setTabValue] = React.useState("1");
  const [duration, setDuration] = React.useState([30]);
  const [emailListError, setEmailListError] = React.useState();
  const [emailListSuccess, setEmailListSuccess] = React.useState();
  const [shareLoading, setShareLoading] = React.useState(false);
  const [calendarImageSaveLoading, setCalendarImageSaveLoading] =
    React.useState(false);
  const [pageSave, setPageSaveSuccess] = React.useState("");
  const [slugErrorMessage, setSlugErrorMessage] = React.useState("");
  const [slugChangeSuccess, setSlugChangeSuccess] = React.useState("");
  const [calendarImageSaveError, setCalendarImageSaveError] =
    React.useState("");
  const [calendarImageSaveSuccess, setCalendarImageSaveSuccess] =
    React.useState("");

  const theme = useTheme();
  const [state, dispatch] = React.useReducer(settingsReducer, {
    loading: true,
    day_ranges: [],
  });
  let { slug: originalSlug } = useParams();
  const [slug, setSlug] = React.useState(originalSlug);

  const [updatedSlug, setUpdatedSlug] = React.useState(slug);
  const [failed, setFailed] = React.useState(false);
  const [remindersChecked, setRemindersChecked] = React.useState(
    state.meetingReminder
  );

  const [calendarImageUploadLocation, setCalendarImageUploadLocation] =
    React.useState(FILE_UPLOAD);
  const [calendarImage, setCalendarImage] = React.useState("");
  const [newCalendarImage, setNewCalendarImage] = React.useState(null);
  const [calendarSources, setCalendarSources] = React.useState([]);
  const [selectedSource, setSelectedSource] = React.useState();

  const matchesMediaQuery = useMediaQuery("(max-width:768px)");

  React.useEffect(() => {
    const fetchCalendarSources = async () => {
      const calendarSourcesRes = await fetch(`/api/calendar_source/list`, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
        },
      });
      const calendarSourcesJSON = await calendarSourcesRes.json();
      setCalendarSources(calendarSourcesJSON.calendars);
      setSelectedSource(
        calendarSourcesJSON.calendars.find((cs) => cs.primary).id
      );
    };

    const fetchSettings = async () => {
      await fetchCalendarSources().catch((e) => console.error(e));
      if (create) {
        dispatch({ type: "set-state", state: DEFAULT_STATE });
        return;
      }
      const res = await fetch(`/api/calendar/${slug}`, {
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
        },
      });
      if (res.status >= 400) {
        setFailed(true);
        return;
      }

      const { settings, calendar_image_url, sources } = await res.json();
      const newState = {
        name: settings.name,
        optionalAttendees: settings.optionalAttendees,
        durations: settings.durations,
        calDescription: settings.calDescription,
        meetingReminder: settings.meetingReminder,
        reminderDuration: settings.reminderDuration,
        bookingLink: settings.bookingLink,
        day_ranges: settings.day_ranges || DEFAULT_STATE.day_ranges,
        eventName: settings.eventName || "",
        eventDescription: settings.eventDescription || "",
        break_ranges: settings.break_ranges,
        redirect_url: settings.redirect_url,
        descriptionEditable: settings.descriptionEditable,
        sharedCalendars: convertArrayEmailsToString(settings.sharedCalendars),
        usePublicTitle: settings.usePublicTitle,
      };
      const durations = settings.durations || [30];
      setDuration(durations);
      setCalendarImage(calendar_image_url);
      setRemindersChecked(settings.meetingReminder);
      if (sources[0]) {
        setSelectedSource(sources[0].id);
      }
      dispatch({ type: "set-state", state: newState });
    };
    fetchSettings();
  }, [slug, create]);

  const changeBookingSlug = async (newBookingSlug) => {
    const currentSlug = state.slug || slug;
    if (currentSlug === newBookingSlug) {
      return;
    }

    const res = await fetch("/api/change-booking-slug", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": getCookie("csrftoken"),
      },
      body: JSON.stringify({
        currentSlug,
        newBookingSlug,
      }),
    });

    const data = await res.json();
    // Check if the response is false
    if (!data.change) {
      setSlugErrorMessage("This calendar URL is already taken.");
    } else {
      dispatch({
        type: "update-slug",
        slug: newBookingSlug,
      });
      setSlug(newBookingSlug);
      setSlugChangeSuccess(`Slug successfully changed to ${newBookingSlug}!`);
    }
  };

  // This useEffect is used to create debouncing functionality for updating slugs. This will
  // automatically submit 1 second after the user has stopped typing.
  React.useEffect(() => {
    const updateSlug = setTimeout(() => {
      changeBookingSlug(updatedSlug);
    }, 500);

    return () => clearTimeout(updateSlug);
    // We aren't including the function in the dependency array because we don't want the useEffect to
    // re-render after we update the `slug` state value. If we did include it, it would cause this useEffect
    // to fire again and ultimately just return. We can safely leave it out since the function will still
    // appropriately be called with the updated state values.
  }, [updatedSlug]);

  const saveSettings = async () => {
    const res = await fetch(
      create ? "/api/calendar/create" : `/api/calendar/edit/${slug}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken"),
        },
        body: JSON.stringify({
          ...state,
          name,
          durations: duration,

          // Easier on server to have these as list
          // Make sure we don't null out this field so overrides to blank work
          sharedCalendars:
            convertStringEmailsToArray(state.sharedCalendars) || "",
        }),
      }
    );

    const newSlugJSON = await res.json();
    const newSlug = newSlugJSON.booking_slug;

    if (res.status >= 400) {
      alert(
        "Calendar save failed. Email info@freeblocksapp.com for more info or try again with new settings."
      );
      return;
    }

    // TODO - error handle creation fail
    // TODO - have some toast for success
    if (res.status === 200) {
      const message = (
        <p>
          Booking page saved! You can continue editing or{" "}
          <Link href="/">click this link to view your booking pages.</Link>
        </p>
      );
      setPageSaveSuccess(message);
      setCreate(false);
      setSlug(newSlug);
    } else {
      setPageSaveSuccess(
        "Calendar failed to save. Try again or contact info@freeblocksapp.com"
      );
    }
  };

  useKeyPress(["s"], () => saveSettings());
  if (failed) {
    return (
      <div style={{ margin: "auto", marginTop: 16, width: "65%" }}>
        <Typography>
          The calendar you are trying to access does not exist or could not be
          accessed. It is possible you modified the booking slug. Please email
          info@freeblocksapp.com if you believe this message is in error.
        </Typography>
      </div>
    );
  }

  if (state.loading) {
    return <CircularProgress style={{ marginTop: 16 }} />;
  }

  const name = state.name;

  const handleMeetingReminderChange = (event) => {
    event.preventDefault();

    if (isPremium) {
      dispatch({
        type: "update-meeting-reminder",
        meetingReminder: event.target.checked,
      });
      setRemindersChecked(event.target.checked);
    } else {
      if (window.klaviyo) {
        window.klaviyo.track("Upgrade Prompt", {
          reason: "meetingReminders",
        });
      }
      dispatch({
        type: "update-meeting-reminder",
        meetingReminder: false,
      });
      setRemindersChecked(false);
      setOpenModal(true);
    }
  };

  const handleDescriptionClick = (event) => {
    if (isPremium) {
      dispatch({
        type: 'update-cal-description"',
        calDescription: event.target.value,
      });
    } else {
      if (window.klaviyo) {
        window.klaviyo.track("Upgrade Prompt", {
          reason: "meetingDescription",
        });
      }
      setOpenModal(true);
    }
  };

  const testCalendarAccess = async (emails) => {
    const isValid = validateEmails(state.sharedCalendars);
    if (!isValid) {
      setEmailListError(
        "Invalid email list. Please check all emails are comma separated and correctly formatted."
      );
      return;
    }

    // Call the server to check if the user has access to the passed calendars
    setShareLoading(true);
    const res = await fetch(`/api/test-shared-calendar-access`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": getCookie("csrftoken"),
      },
      body: JSON.stringify({
        sharedCalendars: convertStringEmailsToArray(state.sharedCalendars),
      }),
    });
    if (res.status >= 500) {
      setEmailListError(
        "Our server encountered an error. Please email info@freeblocksapp.com if this error persists"
      );
      setShareLoading(false);
      return;
    }

    const shareData = await res.json();
    if (shareData.success) {
      setEmailListSuccess(
        "You have access to all e-mails listed! You are ready to share your group calendar."
      );
    } else {
      setEmailListError(shareData.error);
    }

    setShareLoading(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  const handleSlugClick = (event) => {
    if (!isPremium) {
      setOpenModal(true);
    }
  };

  const handleSlugUpdate = (newBookingSlug) => {
    if (isPremium) {
      setUpdatedSlug(newBookingSlug);
    } else {
      setOpenModal(true);
    }
  };

  const handleBrandImageUploadLocationChange = (event, newInput) => {
    if (newInput !== null) {
      setCalendarImageUploadLocation(newInput);
      setNewCalendarImage(null);
    }
  };

  const onFileImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setNewCalendarImage(event.target.files[0]);
    }
  };

  const handleCalendarImageSave = async () => {
    // Perform save logic to store brand image as file in S3 or as a URL
    setCalendarImageSaveLoading(true);
    try {
      if (calendarImageUploadLocation === FILE_UPLOAD) {
        // Get the presigned URL from the server so that we can post the file in the S3 bucket
        const res = await fetch("/api/user/branding/get-s3-post-url", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
          },
          body: JSON.stringify({
            file_name: newCalendarImage.name,
            file_type: newCalendarImage.type,
          }),
        });
        if (res.status >= 400) {
          throw res.statusText;
        }

        // Once we have it, pull out the fields and store it as form data
        // Then upload the file and data to the URL given
        const presignedUrlData = await res.json();
        const postData = new FormData();
        for (const key in presignedUrlData.fields) {
          postData.append(key, presignedUrlData.fields[key]);
        }
        postData.append("file", newCalendarImage);

        const s3UploadRes = await fetch(presignedUrlData.url, {
          method: "POST",
          body: postData,
        });

        if (s3UploadRes.status >= 400) {
          throw s3UploadRes.statusText;
        }

        // Once we know this has succeeded, save the file name to the user
        const updateCalendarRes = await fetch(
          "/api/calendar/image/add-file-name",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": getCookie("csrftoken"),
            },
            body: JSON.stringify({
              currentSlug: state.slug || slug,
              fileName: newCalendarImage.name,
            }),
          }
        );

        if (updateCalendarRes.status >= 400) {
          throw updateCalendarRes.statusText;
        } else {
          dispatch({
            type: "update-calendar-image",
            calendarImage: newCalendarImage.name,
          });
          const { url } = await updateCalendarRes.json();
          setCalendarImage(url);

          setCalendarImageSaveSuccess("Your calendar image has been updated!");
        }
      } else {
        // Post endpoint that just saves the image URL to the user
        const updateCalendarRes = await fetch(
          "/api/calendar/image/add-file-url",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": getCookie("csrftoken"),
            },
            body: JSON.stringify({
              currentSlug: state.slug || slug,
              fileUrl: newCalendarImage,
            }),
          }
        );

        if (updateCalendarRes.status >= 400) {
          throw updateCalendarRes.statusText;
        } else {
          const { url } = await updateCalendarRes.json();
          dispatch({
            type: "update-calendar-image",
            calendarImage: url,
          });
          setCalendarImage(url);

          setCalendarImageSaveSuccess("Your calendar image has been updated!");
        }
      }
    } catch (err) {
      setCalendarImageSaveError(err);
    } finally {
      setNewCalendarImage(null);
      setCalendarImageSaveLoading(false);
    }
  };

  const OutsideBoxSX = matchesMediaQuery
    ? {}
    : { display: "flex", height: "100vh" };
  const TabListBoxSX = matchesMediaQuery
    ? {}
    : {
        width: "20vw",
        height: "100vh",
      };
  const TabListSX = matchesMediaQuery
    ? { borderBottom: 1, width: "100vw" }
    : {
        borderRight: 1,
        height: "100vh",
        width: "20vw",
        paddingTop: "12px",
        paddingLeft: "8px",
      };
  const TabPanelStyle = matchesMediaQuery ? { padding: 0 } : {};

  const TabStyle = (value) => {
    return matchesMediaQuery
      ? {}
      : tabValue === value
      ? {
          justifyContent: "flex-start",
          backgroundColor: "#eeeeee",
        }
      : { justifyContent: "flex-start" };
  };
  const StyledTab = styled((props) => (
    <Tab
      sx={{
        fontSize: 15,
        textAlign: "center",
      }}
      style={TabStyle(props.value)}
      {...(props.icon
        ? { iconPosition: matchesMediaQuery ? "top" : "start" }
        : {})}
      {...props}
    />
  ))(({ theme }) => ({
    textTransform: "none",
    textAlign: "left",
    fontWeight: theme.typography.fontWeightBold,
  }));

  return (
    <TabContext value={tabValue}>
      <Box sx={{ ...OutsideBoxSX }}>
        <Box
          sx={{
            ...TabListBoxSX,
          }}
        >
          <TabList
            orientation={matchesMediaQuery ? "horizontal" : "vertical"}
            scrollButtons={matchesMediaQuery}
            allowScrollButtonsMobile={matchesMediaQuery}
            variant={matchesMediaQuery ? "scrollable" : "fullWidth"}
            value={tabValue}
            onChange={handleTabChange}
            sx={{
              ...TabListSX,
              borderColor: "divider",
            }}
          >
            <StyledTab label={name} value="0" disabled />
            <StyledTab
              label={GENERAL}
              value="1"
              icon={<DisplaySettingsIcon />}
            />
            <StyledTab
              label={VIDEO_CONFERENCE_DETAILS}
              value="2"
              icon={<VideoCallIcon />}
            />
            <StyledTab
              label={EVENT_DETAILS}
              value="3"
              icon={<EventNoteIcon />}
            />
            <StyledTab
              label={CALENDAR_IMAGE}
              value="4"
              icon={<InsertPhotoIcon />}
            />
            <StyledTab
              label={GROUP_CALENDAR}
              value="5"
              icon={<CalendarMonthIcon />}
            />
            <StyledTab label={WORKING_HOURS} value="6" icon={<WorkIcon />} />
            <StyledTab label={BREAKS} value="7" icon={<WorkOffIcon />} />
          </TabList>
        </Box>

        <Box
          sx={{
            width: "100%",
          }}
        >
          <div className="centerContent-set">
            <SettingsAlert
              message={pageSave}
              messageSetter={setPageSaveSuccess}
              severity="success"
            />
            <SettingsAlert
              message={slugErrorMessage}
              messageSetter={setSlugErrorMessage}
              severity="error"
            />
            <SettingsAlert
              message={slugChangeSuccess}
              messageSetter={setSlugChangeSuccess}
              severity="success"
            />
            <SettingsAlert
              message={emailListError}
              messageSetter={setEmailListError}
              severity="error"
            />
            <SettingsAlert
              message={emailListSuccess}
              messageSetter={setEmailListSuccess}
              severity="success"
            />
            <SettingsAlert
              message={calendarImageSaveError}
              messageSetter={setCalendarImageSaveError}
              severity="error"
            />
            <SettingsAlert
              message={calendarImageSaveSuccess}
              messageSetter={setCalendarImageSaveSuccess}
              severity="success"
            />
          </div>

          <TabPanel
            style={{ ...TabPanelStyle }}
            className={matchesMediaQuery ? "centerContent-set" : ""}
            value="1"
          >
            <Card variant="outlined" className="card-set centerContent-set">
              <CardContent className="card-content">
                <FormGroup>
                  <Typography
                    variant="h5"
                    style={{ marginBottom: 12, marginTop: 12 }}
                  >
                    {GENERAL}
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <TextField
                      className="setting-field"
                      fullWidth
                      label="Calendar Name"
                      helperText="The name of your calendar. Check the box below to make this name public (defaults to {YourName}'s Calendar)."
                      size="small"
                      id="outlined-basic"
                      variant="outlined"
                      style={{ marginBottom: 12 }}
                      defaultValue={name}
                      onChange={(event) => {
                        dispatch({
                          type: "update-name",
                          name: event.target.value,
                        });
                      }}
                      required
                    />
                    <FormGroup>
                      <FormControlLabel
                        style={{
                          marginBottom: 24,
                          borderColor: "gray",
                          border: "1px solid #d3d3d3",
                          borderRadius: 4,
                          marginLeft: 0,
                          marginRight: 0,
                        }}
                        control={
                          <div style={{ marginLeft: 4 }}>
                            <Switch
                              checked={!!state.usePublicTitle}
                              size="small"
                              onChange={(event) => {
                                dispatch({
                                  type: "use-public-title",
                                  usePublicTitle: event.target.checked,
                                });
                              }}
                            />
                          </div>
                        }
                        label={
                          <p
                            style={{ marginLeft: 8, padding: 0, lineHeight: 1 }}
                          >
                            Show public title as{" "}
                            {state.name ||
                              userContext.user.first_name +
                                " " +
                                userContext.user.last_name}
                            's Calendar
                          </p>
                        }
                        helperText="Book"
                      />
                    </FormGroup>
                    <FormControl
                      className="setting-field"
                      style={{ marginTop: 8, marginBottom: 12 }}
                      sx={{
                        width: "100%",
                        "& .MuiTextField-root": { width: "100%" },
                      }}
                    >
                      <InputLabel>Calendar Source</InputLabel>
                      <Select
                        labelId="calendar-sources"
                        defaultValue={selectedSource || "Primary"}
                        id="calendar-sources"
                        label="Calendar Source"
                        input={<OutlinedInput label="Calendar Source" />}
                        size="small"
                        onChange={(event) => {
                          const jsonValue = calendarSources.find(
                            (cs) => cs.id === event.target.value
                          );
                          dispatch({
                            type: "update-calendar-source",
                            source: jsonValue,
                          });
                        }}
                      >
                        {calendarSources.map((sourceData) => {
                          return (
                            <MenuItem key={sourceData.id} value={sourceData.id}>
                              <span>{sourceData.name}</span>
                              <img
                                style={{ marginLeft: 8 }}
                                src={GoogleImage}
                                height={25}
                                alt=""
                              />
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText>
                        (Optional) Modify your calendar's source calendar. By
                        default, this will be your primary calendar. If you want
                        to change it to another calendar, choose from the
                        dropdown. Note that the owner of all created events will
                        also be this calendar.
                      </FormHelperText>
                    </FormControl>
                  </Box>

                  <div
                    className="setting-field"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 12,
                    }}
                  >
                    <TextField
                      className="base-slug"
                      label="Booking URL"
                      value="https://freeblockscal.com/book/"
                      size="small"
                      style={{ marginBottom: 12, width: "75%" }}
                      inputProps={{
                        readOnly: true,
                      }}
                    />

                    <TextField
                      className="slug-field"
                      size="small"
                      id="outlined-basic"
                      variant="outlined"
                      style={{ marginBottom: 12 }}
                      defaultValue={slug}
                      onChange={(event) => handleSlugUpdate(event.target.value)}
                      onClick={handleSlugClick}
                      inputProps={{
                        readOnly: !isPremium,
                        maxLength: 20,
                      }}
                      required
                    />
                  </div>
                  <div
                    style={{
                      marginBottom: 8,
                      color: "rgba(0, 0, 0, 0.6)",
                      fontSize: "0.75rem",
                      textAlign: "left",
                      marginLeft: 14,
                    }}
                  >
                    WARNING: Modifying this slug will break any existing
                    calendars using the previous slug. It is recommended you
                    only change this slug for new calendars.
                  </div>

                  <TextField
                    className="setting-field"
                    style={{ marginTop: 8, marginBottom: 12 }}
                    label="Description"
                    helperText="Additional details or specific instructions that will be displayed on your booking page."
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    onClick={handleDescriptionClick}
                    value={state.calDescription} // Use 'value' instead of 'defaultValue'
                    multiline
                    rows={3}
                    onChange={(event) => {
                      if (isPremium) {
                        dispatch({
                          type: "update-cal-description",
                          calDescription: event.target.value,
                        });
                      } else {
                        if (window.klaviyo) {
                          window.klaviyo.track("Upgrade Prompt", {
                            reason: "meetingDescriptionUpdate",
                          });
                        }
                        dispatch({
                          type: "update-cal-description",
                          calDescription: "",
                        });
                      }
                    }}
                  />
                  <FormControl
                    className="setting-field"
                    style={{ marginTop: 8, marginBottom: 12 }}
                  >
                    <InputLabel>Meeting Duration</InputLabel>
                    <Select
                      multiple
                      id="durations"
                      defaultValue={duration}
                      value={duration}
                      onChange={(e) => handleDurationChange(e, setDuration)}
                      input={<OutlinedInput label="Meeting Duration" />}
                      renderValue={(selected) => (
                        <Stack gap={1} direction="row" flexWrap="wrap">
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={
                                DURATIONS.find(
                                  (option) => option.value === value
                                ).label
                              }
                              onDelete={() =>
                                setDuration(
                                  duration.filter((item) => item !== value)
                                )
                              }
                              deleteIcon={
                                <CancelIcon
                                  onMouseDown={(event) =>
                                    event.stopPropagation()
                                  }
                                />
                              }
                            />
                          ))}
                        </Stack>
                      )}
                    >
                      {DURATIONS.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          style={{
                            fontWeight: theme.typography.fontWeightMedium,
                          }}
                        >
                          {option.label}
                          {duration.includes(option.value) ? (
                            <CheckIcon color="info" />
                          ) : null}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControlLabel
                    control={
                      <Switch
                        className="setting-field"
                        checked={!!remindersChecked}
                        onChange={handleMeetingReminderChange}
                        color="primary"
                      />
                    }
                    label={
                      <span style={{ whiteSpace: "nowrap" }}>
                        Meeting Reminders
                      </span>
                    }
                  />

                  <Dialog
                    open={openModal}
                    onClose={handleCloseModal}
                    style={{ overflow: "hidden" }}
                  >
                    <DialogContent
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        overflowX: "hidden",
                      }}
                    >
                      <p>
                        To access this feature, please upgrade to a premium
                        account.
                      </p>
                      <div style={{ width: "130%" }}>
                        <stripe-pricing-table
                          pricing-table-id={tableKey}
                          publishable-key={publishableStripeKey}
                          customer-email={userContext.user.email}
                        ></stripe-pricing-table>
                      </div>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseModal} color="primary">
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>

                  {state.meetingReminder && (
                    <FormControl fullWidth>
                      <InputLabel
                        className="duration-before-select"
                        id="demo-simple-select-label"
                        size="small"
                      >
                        Time
                      </InputLabel>
                      <Select
                        defaultValue={state.reminderDuration}
                        id="duration-before"
                        label="Time"
                        size="small"
                        onChange={(event) => {
                          dispatch({
                            type: "update-reminder-duration",
                            reminderDuration: event.target.value,
                          });
                        }}
                        className="duration-select"
                      >
                        <MenuItem value={15}>15 Minutes Before</MenuItem>
                        <MenuItem value={30}>30 Minutes Before</MenuItem>
                        <MenuItem value={45}>45 Minutes Before</MenuItem>
                        <MenuItem value={60}>1 Hour Before</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                  <TextField
                    className="setting-field"
                    label="Redirect URL"
                    helperText="URL to redirect to on successful booking."
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    style={{ marginBottom: 12, marginTop: 12 }}
                    value={state.redirect_url} // Use 'value' instead of 'defaultValue'
                    onClick={() => {
                      if (!isPremium) {
                        window.klaviyo.track("Upgrade Prompt", {
                          reason: "redirectURL",
                        });
                        setOpenModal(true);
                      }
                    }}
                    onChange={(event) => {
                      if (isPremium) {
                        dispatch({
                          type: "update-redirect-url",
                          redirect_url: event.target.value,
                        });
                      } else {
                        if (window.klaviyo) {
                          window.klaviyo.track("Upgrade Prompt", {
                            reason: "redirectURL",
                          });
                        }
                        setOpenModal(true);
                      }
                    }}
                  />
                </FormGroup>
              </CardContent>
            </Card>
          </TabPanel>

          <TabPanel
            style={{ ...TabPanelStyle }}
            className={matchesMediaQuery ? "centerContent-set" : ""}
            value="2"
          >
            <Card variant="outlined" className="card-set">
              <CardContent className="card-content">
                <FormGroup>
                  <Typography
                    variant="h5"
                    style={{ marginBottom: 12, marginTop: 12 }}
                  >
                    {VIDEO_CONFERENCE_DETAILS}
                  </Typography>
                  <FormControl
                    className="setting-field"
                    style={{ marginTop: 8, marginBottom: 12 }}
                  >
                    <InputLabel>Meeting URL</InputLabel>
                    <Select
                      labelId="meeting-url"
                      defaultValue={
                        state.bookingLink
                          ? state.bookingLink.includes("google")
                            ? "google"
                            : "zoom"
                          : "s"
                      }
                      id="meeting-url"
                      label="Meeting URL Integration"
                      input={<OutlinedInput label="Meeting URL Integration" />}
                      size="small"
                      onChange={(event) => {
                        dispatch({
                          type: "update-booking-link",
                          bookingLink: event.target.value,
                        });
                      }}
                    >
                      {[
                        { name: "Google", image: GoogleImage, value: "google" },
                        { name: "Zoom", image: ZoomImage, value: "zoom" },
                        { name: "No Selection", image: null, value: "none" },
                      ].map((sourceData) => {
                        return (
                          <MenuItem
                            key={sourceData.name}
                            value={sourceData.value}
                          >
                            <span>{sourceData.name}</span>
                            {sourceData.image && (
                              <img
                                style={{ marginLeft: 8 }}
                                src={sourceData.image}
                                height={25}
                              />
                            )}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText style={{ marginBottom: 12 }}>
                      Use Google or Zoom to generate a conference link for the
                      meeting. Note selecting Zoom will only have an impact if
                      you have integrated{" "}
                      <a href="/integrations/zoom">with Zoom</a>.
                      {state.bookingLink &&
                        state.bookingLink !== "google" &&
                        state.bookingLink !== "zoom" &&
                        ` You are using a URL override (${state.bookingLink}) and this integration will not take effect`}
                    </FormHelperText>
                    <TextField
                      className="setting-field"
                      label="Meeting URL Override (optional)"
                      size="small"
                      helperText="Use your own URL for meetings; useful if you can't use our integrations. For example, you could use your personal Zoom link at https://zoom.us/profile under Personal Meeting ID. Only Google and Zoom URLs are supported at this time."
                      id="outlined-basic"
                      variant="outlined"
                      style={{ marginTop: 8, marginBottom: 12 }}
                      defaultValue={
                        state.bookingLink === "zoom" ||
                        state.bookingLink === "google"
                          ? ""
                          : state.bookingLink
                      }
                      onChange={(event) => {
                        dispatch({
                          type: "update-booking-link",
                          bookingLink: event.target.value,
                        });
                      }}
                    />
                  </FormControl>
                </FormGroup>
              </CardContent>
            </Card>
          </TabPanel>

          <TabPanel
            style={{ ...TabPanelStyle }}
            className={matchesMediaQuery ? "centerContent-set" : ""}
            value="3"
          >
            <Card variant="outlined" className="card-set">
              <CardContent className="card-content">
                <Typography
                  variant="h5"
                  style={{ marginBottom: 12, marginTop: 12 }}
                >
                  {EVENT_DETAILS}
                </Typography>
                <FormGroup>
                  <TextField
                    style={{ marginTop: 8 }}
                    className="setting-field"
                    label="Optional Attendees (New!)"
                    helperText="Emails of optional attendees. Comma-separate for multiple."
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    defaultValue={state.optionalAttendees}
                    onChange={(event) => {
                      dispatch({
                        type: "update-optional-attendees",
                        optionalAttendees: event.target.value,
                      });
                    }}
                  />
                  <TextField
                    style={{ marginTop: 16 }}
                    className="setting-field"
                    label="Event Name"
                    helperText="The event name that will appear on calendar events. Leaving this value blank will allow users to enter in their own event name. If checked, users cannot modify event description unless the 'Allow editable description' box is checked."
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    defaultValue={state.eventName}
                    onChange={(event) => {
                      dispatch({
                        type: "update-event-name",
                        eventName: event.target.value,
                      });
                    }}
                  />
                  <TextField
                    style={{ marginTop: 8 }}
                    className="setting-field"
                    label="Event Description"
                    helperText="The event description that will appear on calendar events. Leaving this value blank will allow users to enter their own description."
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    defaultValue={state.eventDescription}
                    onChange={(event) => {
                      dispatch({
                        type: "update-event-description",
                        eventDescription: event.target.value,
                      });
                    }}
                  />

                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!state.descriptionEditable}
                          size="small"
                          onChange={(event) => {
                            dispatch({
                              type: "update-event-desc-editable",
                              descriptionEditable: event.target.checked,
                            });
                          }}
                        />
                      }
                      label="Allow editable description if name or description set"
                      helperText="Book"
                    />
                  </FormGroup>
                </FormGroup>
              </CardContent>
            </Card>
          </TabPanel>

          <TabPanel
            style={{ ...TabPanelStyle }}
            className={matchesMediaQuery ? "centerContent-set" : ""}
            value="4"
          >
            <Card variant="outlined" className="card-set">
              <CardContent className="card-content">
                <Typography variant="h5" style={{ marginTop: 12 }}>
                  {CALENDAR_IMAGE}
                </Typography>
                <FormGroup>
                  <p>
                    Your calendar image will replace your brand image or the
                    default FreeBlocks branding and will be shown on all of your
                    calendars.
                  </p>
                  {calendarImage ? (
                    <img
                      className="brandImage centerAlign"
                      src={calendarImage}
                      alt="current calendar img"
                    />
                  ) : profileBrandImage ? (
                    <img
                      className="brandImage centerAlign"
                      src={profileBrandImage}
                      alt="current brand"
                    />
                  ) : (
                    <>
                      <p>
                        A brand or calendar image has not been uploaded. We will
                        default to FreeBlocks branding.
                      </p>
                      <img
                        className="brandImage centerAlign"
                        src={FB_IMAGE_WIX}
                        alt="freeblocks brand img"
                      />
                    </>
                  )}
                  <div className="calendarImageAccordion">
                    <Accordion
                      defaultExpanded={!Boolean(calendarImage)}
                      disableGutters
                      elevation={0}
                      style={{
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        sx={{
                          padding: 0,
                          margin: 0,
                          color: "blue",
                          "& .MuiAccordionSummary-content": {
                            width: "auto",
                            flexGrow: 0,
                            justifyContent: "center",
                            margin: 0,
                          },
                        }}
                      >
                        Upload New Image
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: 0, margin: 0 }}>
                        <p className="accordionText centerAlign">
                          Upload an image from your file system or from a URL:
                        </p>
                        {newCalendarImage && (
                          <>
                            <img
                              className="brandImage centerAlign"
                              src={
                                calendarImageUploadLocation === FILE_UPLOAD
                                  ? URL.createObjectURL(newCalendarImage)
                                  : newCalendarImage
                              }
                              alt="preview"
                            />
                            <br />
                            <br />
                          </>
                        )}

                        <div className="calendarImageUpload">
                          <ToggleButtonGroup
                            value={calendarImageUploadLocation}
                            exclusive
                            onChange={handleBrandImageUploadLocationChange}
                            aria-label="brand image input"
                          >
                            <ToggleButton value={FILE_UPLOAD}>
                              <UploadFile titleAccess="Upload file from your system" />
                            </ToggleButton>
                            <ToggleButton value={URL_UPLOAD}>
                              <Http />
                            </ToggleButton>
                          </ToggleButtonGroup>
                          {calendarImageUploadLocation === FILE_UPLOAD ? (
                            <TextField
                              size="medium"
                              type="file"
                              required
                              className="setting-field image-upload-field"
                              inputProps={{
                                accept:
                                  "image/png, image/jpeg, image/jpg, image/svg",
                              }}
                              onChange={onFileImageChange}
                            />
                          ) : (
                            <TextField
                              size="medium"
                              type="url"
                              placeholder="https://imagelocation.com"
                              required
                              className="setting-field image-upload-field"
                              inputProps={{ maxLength: 300 }}
                              onChange={(e) =>
                                setNewCalendarImage(e.target.value)
                              }
                            />
                          )}
                          <LoadingButton
                            variant="contained"
                            color="primary"
                            onClick={handleCalendarImageSave}
                            disabled={
                              calendarImageSaveLoading || !newCalendarImage
                            }
                            loading={calendarImageSaveLoading}
                          >
                            Save
                          </LoadingButton>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </FormGroup>
              </CardContent>
            </Card>
          </TabPanel>

          <TabPanel
            style={{ ...TabPanelStyle }}
            className={matchesMediaQuery ? "centerContent-set" : ""}
            value="5"
          >
            <Card variant="outlined" className="card-set">
              <CardContent className="card-content">
                <Typography
                  variant="h5"
                  style={{ marginBottom: 12, marginTop: 12 }}
                >
                  {GROUP_CALENDAR}
                </Typography>
                <FormGroup>
                  <TextField
                    className="setting-field"
                    label="Emails"
                    helperText={
                      <p>
                        Create a shared group calendar to track availability by
                        entering comma-separated email addresses. For Google
                        Calendar, you must{" "}
                        <a href="https://support.google.com/calendar/answer/37082?hl=en">
                          have access
                        </a>{" "}
                        to the other calendars listed.
                        <br />
                        <br />
                        Please note emails will only save given a successful
                        test using the "Test Shared Calendar Access" button.
                      </p>
                    }
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                    multiline
                    rows={3}
                    defaultValue={state.sharedCalendars}
                    onChange={(event) => {
                      dispatch({
                        type: "update-shared-calendars",
                        sharedCalendars: event.target.value,
                      });
                    }}
                  />
                  <div className="shared-cal-button">
                    <LoadingButton
                      loading={shareLoading}
                      variant="outlined"
                      onClick={testCalendarAccess}
                    >
                      Test Shared Calendar Access
                    </LoadingButton>
                  </div>
                </FormGroup>
              </CardContent>
            </Card>
          </TabPanel>

          <TabPanel
            style={{ ...TabPanelStyle }}
            className={matchesMediaQuery ? "centerContent-set" : ""}
            value="6"
          >
            <Card variant="outlined" className="card-set">
              <CardContent className="card-content">
                <Typography
                  variant="h5"
                  style={{ marginBottom: 12, marginTop: 12 }}
                >
                  {WORKING_HOURS}
                </Typography>
                <div className="hour-field">
                  {state.day_ranges.map((day, index) => (
                    <DayWorkingHourForm
                      dayState={day}
                      key={index}
                      dispatch={dispatch}
                    />
                  ))}
                </div>
              </CardContent>
            </Card>
          </TabPanel>

          <TabPanel
            style={{ ...TabPanelStyle }}
            className={matchesMediaQuery ? "centerContent-set" : ""}
            value="7"
          >
            <Card variant="outlined" className="card-set">
              <CardContent className="card-content">
                <Typography variant="h5" style={{ marginBottom: 12 }}>
                  {" "}
                  {BREAKS}
                </Typography>
                <div className="hour-field">
                  <BreakAdder
                    dispatch={dispatch}
                    slotMetadataRaw={state.break_ranges}
                  />
                </div>
              </CardContent>
            </Card>
          </TabPanel>

          <Fab
            variant="extended"
            color="primary"
            aria-label="add"
            onClick={saveSettings}
            style={{
              margin: 0,
              top: "auto",
              right: 20,
              bottom: 100,
              left: "auto",
              position: "fixed",
            }}
          >
            {create ? <Add sx={{ mr: 1 }} /> : <Save sx={{ mr: 1 }} />}
            {create ? "Create" : "Save"}
          </Fab>
        </Box>
      </Box>
    </TabContext>
  );
};
